/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Dropzone } from '@valid-eval/shared-react-components';

import { Component } from 'react';
import classNames from 'classnames';
import classes from './FileDropzone.module.scss';
import { getEnvVar } from 'config';
import { isRunningOnIE } from 'utils';

const MAX_FILE_SIZE = Number(
  getEnvVar('REACT_APP_MAX_FILE_SIZE', (1024 * 1024 * 1024 * 10).toString()),
);
// Default increased from 100MB to 10GB and changed to an environment variable as of 12 Jun 2023

const IS_IE_BROWSER = isRunningOnIE();

class FileDropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      rejected: false,
      isUploading: false,
      current: props.current,
      progress: null,
    };
    /*
     * We need to revisit this component.
     * I'm not sure why it's necessary to keep a local copy of props.current.
     * This can lead to bugs if not handled properly.
     */
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.current && props.current.name && props.current.url && prevState.current !== null) {
      return { current: props.current };
    }
    return null;
  }

  onProgress = (progress, status, file) => {
    this.setState({ progress });
  };

  onDropRejected = (rejectedFiles) => {
    const rejectedText =
      rejectedFiles[0].size > MAX_FILE_SIZE
        ? "The file's size can not exceed 100 MB"
        : 'You can not upload files of this type';

    this.setState({ rejected: true, rejectedText });
  };

  onDropAccepted = () => {
    this.setState({ isUploading: true });
  };

  clearSelection = () => {
    const confirmText = `Are you sure you want to clear selected file: ${this.selectedFilename()}`;
    if (window.confirm(confirmText)) {
      this.setState({ files: [], rejected: false, current: null }, () => {
        this.props.onDropFile && this.props.onDropFile({ [this.props.name]: [] });
      });
    }
  };

  selectedFilename() {
    /*
     * Selected file name gives priority to the name of the blob file.
     * It uses as fallback the name of the current selected file in case the file was already uploaded
     */
    return this.state.files.length ? this.state.files[0].name : this.state.current.name;
  }

  handleFinishedUpload = (info) => {
    const url = info.signedUrl.split('?')[0];

    this.setState(
      {
        files: [info.file],
        rejected: false,
        isUploading: false,
        current: { name: info.file.name, url: URL.createObjectURL(info.file) },
      },
      () => {
        this.props.input.onChange({ [this.props.name]: url }); // Updates redux-form data
        this.props.onDropFile && this.props.onDropFile({ [this.props.name]: url });
      },
    );
  };

  handleFailedUpload = (message, file) => {
    const errorMessage = `Can not upload file ${file && file.name}. Please contact your organizer`;
    this.setState({ isUploading: false, files: [], rejected: true, rejectedText: errorMessage });
  };

  renderDropzone(acceptedFileType, disabled, classes, disableDelete) {
    const uploadOptions = {
      signingUrlQueryParams: {
        event_id: this.props.eventId,
        team_id: this.props.teamId,
      },
      uploadRequestHeaders: {
        'x-amz-acl': 'private',
      },
    };

    const acceptedFileTypeForNonIEbrowser = IS_IE_BROWSER ? undefined : acceptedFileType;
    const hasLoadedFile = this.state.current && this.state.current.url;

    const acceptedFormats = acceptedFileTypeForNonIEbrowser.split(',').reduce((accum, format) => {
      // Patch to filter only kml files in file selector
      if (format === 'application/vnd.google-earth.kml+xml') {
        return { ...accum, 'application/vnd.google-earth.kml+xml': ['.kml'] };
      }
      // Patch to filter only geojson files in file selector
      if (format === 'application/geo+json') {
        return { ...accum, 'application/geo+json': ['.geojson'] };
      }
      return { ...accum, [format]: [] };
    }, {});

    return (
      <div>
        {hasLoadedFile ? (
          <div className={classNames(classes.containerFile)}>
            <a
              className={classes.selected}
              href={this.state.current.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {this.selectedFilename()}
            </a>
            {!disabled && !disableDelete && (
              <Button
                className={'p-0'}
                variant={'link'}
                onClick={this.clearSelection}
                data-test-id="clear"
              >
                <i className={'fa-regular fa-trash-can'} />
              </Button>
            )}
          </div>
        ) : null}
        <div className={'hideInPrint'}>
          <Dropzone
            disabled={disabled}
            s3Url=""
            maxSize={MAX_FILE_SIZE}
            onFinish={this.handleFinishedUpload}
            accept={acceptedFormats}
            multiple={false}
            onProgress={this.onProgress}
            onDropAccepted={this.onDropAccepted}
            onDropRejected={this.onDropRejected}
            onError={this.handleFailedUpload}
            signingUrlQueryParams={uploadOptions.signingUrlQueryParams}
            uploadRequestHeaders={uploadOptions.uploadRequestHeaders}
            disableDragAndDrop
          />
        </div>
      </div>
    );
  }

  render() {
    const { acceptedFileType, disabled, disableDelete } = this.props;
    return (
      <div className={classNames(classes.container)}>
        {this.renderDropzone(acceptedFileType, disabled, classes, disableDelete)}
        {this.state.rejected ? (
          <div className={classes.alertContainer}>
            <p className="alert alert-danger">{this.state.rejectedText}</p>
          </div>
        ) : undefined}
      </div>
    );
  }
}

FileDropzone.displayName = 'FileDropzone';
export default FileDropzone;
