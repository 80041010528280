import { Dropdown } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useState } from 'react';

import DocumentLink from 'components/DocumentLink';
import { getArtifactItemsLoading, getDocuments, getDocumentsByEvaluation } from 'data/reducers';
import { downloadAllArtifacts } from 'data/actions/artifactItems';
import { connect } from 'react-redux';
import { organizerNav } from 'utils/urls';

type DocumentType = {
  file_name: string;
  name: string;
  type: string;
  value: string;
};

type LinksAndDownloadsProps = {
  showOrganizerLinks?: boolean;
  evaluationId?: string;
  eventId?: string;
  teamId: string;
  loading?: boolean;
  downloadAllArtifacts?: (teamId: string, evaluationId: string | undefined) => void;
  documents?: DocumentType[];
};

const LinksAndDownloads = ({
  evaluationId,
  showOrganizerLinks,
  eventId,
  teamId,
  loading,
  documents,
  downloadAllArtifacts,
}: LinksAndDownloadsProps) => {
  const [show, setShow] = useState(false);
  const isDownloadable = (documents?: DocumentType[]): boolean =>
    documents?.some(
      (document) => !['web', 'url'].includes(document.type) && document.type !== null,
    ) || false;

  const handleToggle = (isOpened: boolean) => {
    setShow(isOpened);
  };

  const handleDownloadAll = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    downloadAllArtifacts?.(teamId, evaluationId);
  };

  return (
    <Dropdown
      onMouseEnter={() => handleToggle(true)}
      onMouseLeave={() => handleToggle(false)}
      onToggle={() => handleToggle(!show)}
      show={show}
    >
      <Dropdown.Toggle
        variant="link"
        id={`team-links-${teamId}`}
        className="align-items-center justify-content-center"
      >
        <i className="fa-solid fa-folder-open"></i>
        Materials
        <i
          className={cx('fa-solid ms-2 fa-sm', {
            'fa-chevron-down': !show,
            'fa-chevron-up': show,
          })}
        ></i>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ marginTop: -2 }}>
        {showOrganizerLinks && (
          <Dropdown.Item href={organizerNav.profile(eventId, 'teams', teamId)}>
            Team Profile
          </Dropdown.Item>
        )}
        {showOrganizerLinks && (
          <Dropdown.Item href={organizerNav.editAssignments(eventId, 'teams', teamId)}>
            Judge Matches
          </Dropdown.Item>
        )}

        {loading && (
          <Dropdown.Item disabled className="justify-content-center align-items-center my-2">
            <i className="fa-duotone fa-spinner fa-spin fa-lg" />
          </Dropdown.Item>
        )}

        {!loading && !documents?.length && !showOrganizerLinks && (
          <Dropdown.Item disabled className="justify-content-center align-items-center my-2">
            No data to display
          </Dropdown.Item>
        )}

        {!loading && isDownloadable(documents) && (documents?.length || 0) > 1 && (
          <Dropdown.Item onClick={handleDownloadAll}>Download All Materials</Dropdown.Item>
        )}

        {!loading &&
          documents?.map((doc) => (
            <DocumentLink
              fileName={doc.file_name}
              name={doc.name}
              type={doc.type}
              value={doc.value}
              key={doc.name}
              className="dropdown-item"
            />
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default connect(
  (state, { evaluationId, teamId, ...ownProps }: LinksAndDownloadsProps) => {
    if (ownProps.documents) return { documents: ownProps.documents, loading: false };

    // TODO: redux-epic: this needs to be removed once state is completely simplified
    const loading = getArtifactItemsLoading(state, teamId);
    const documents = (
      evaluationId ? getDocumentsByEvaluation(state, evaluationId) : getDocuments(state, teamId)
    )?.toJS?.();
    return { documents, loading };
  },
  { downloadAllArtifacts },
)(LinksAndDownloads);
