import DimensionReadOnly from './DimensionReadOnly';

const RubricReadOnly = ({ rubric, children }) => {
  const grades = rubric.get('grades');
  const dimensions = rubric.get('dimensions');

  return (
    <div className="py-2 d-flex flex-column">
      {dimensions.map((dimension, index) => {
        const dimId = String(dimension.get('id'));
        return (
          <DimensionReadOnly
            readOnly={true}
            dimension={dimension}
            grades={grades}
            key={`dim-${dimId}`}
            coloredBackground={index % 2 === 0}
          />
        );
      })}
      {children}
    </div>
  );
};

export default RubricReadOnly;
