import React, { useState } from 'react';
import BaseTitle from '../../../components/Title';
import { ServerImage } from 'components/Image';
import classes from './Title.module.scss';
import cx from 'classnames';
import { getEnvVar } from '../../../config';
import truncate from 'truncate';

const secondaryClass = (selector, role) => {
  if (role === 'org+nav') return classes.org;
  if (role === 'judge') return classes.judge;
  if (getEnvVar('REACT_APP_GOV_ENV') !== '1') {
    return selector ? classes.teamSm : classes.teamMd;
  }
  return selector ? classes.teamLg : classes.teamXl;
};

const Title = ({
  logo,
  title,
  subTitle = 'Registration',
  skipSubTitle = false,
  teamWithCategory = null,
  selector = true,
  role = 'org+nav',
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const categoryText = teamWithCategory && teamWithCategory.get('category');
  const displayText = isExpanded || !categoryText ? categoryText : truncate(categoryText, 45);

  return (
    <div className="clearfix float-start">
      <div className={cx('float-start', classes.logo)}>
        {logo ? <ServerImage fit src={logo} /> : <ServerImage fit src="/images/no-image.png" />}
      </div>
      <div className="float-end pt-2">
        <BaseTitle className={cx(classes.title, secondaryClass(selector, role))}>{title}</BaseTitle>
        {skipSubTitle ? null : (
          <BaseTitle className="d-print-none" component="h2" style={{ marginLeft: '15px' }}>
            {subTitle}
          </BaseTitle>
        )}
        {teamWithCategory && (
          <div className={cx(classes.teamWithCategory, secondaryClass(selector, role))}>
            <BaseTitle component="h2" className="mb-1">
              {teamWithCategory.get('name')}
            </BaseTitle>
            <BaseTitle component="h5" className={classes.category} onClick={toggleExpand}>
              {displayText}
              <i
                className={`fa-solid ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'} ${
                  classes.categoryArrow
                }`}
              ></i>
            </BaseTitle>
          </div>
        )}
      </div>
    </div>
  );
};

export default Title;
