import { useState, useCallback } from 'react';
import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonGroup,
  Dropdown,
} from '@valid-eval/shared-react-components';
import { useTranslation } from 'react-i18next';
import styles from './DropdownSplitButton.module.scss';

type SplitButtonDropdownProps = {
  primaryText: string;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  disabled?: boolean;
  onScheduleChange: (isSchedule: boolean) => void;
  isScheduleComplete: boolean;
  disableScheduleButton: boolean;
};

const SplitButtonDropdown = ({
  primaryText,
  onPrimaryAction,
  onSecondaryAction,
  disabled = false,
  onScheduleChange,
  isScheduleComplete,
  disableScheduleButton,
}: SplitButtonDropdownProps) => {
  const { t } = useTranslation();
  const [isSchedule, setIsSchedule] = useState(false);

  const handleSelect = useCallback(() => {
    setIsSchedule((prev) => {
      const newScheduleState = !prev;
      onScheduleChange(newScheduleState);
      return newScheduleState;
    });
  }, [onScheduleChange]);

  const handlePrimaryClick = useCallback(() => {
    if (isSchedule) {
      onSecondaryAction();
    } else {
      onPrimaryAction();
    }
  }, [isSchedule, onPrimaryAction, onSecondaryAction]);

  const buttonText = isSchedule ? 'Schedule' : primaryText;
  const buttonId =
    buttonText === 'Save & Send' || buttonText === 'Send'
      ? 'communication-template-send'
      : 'communication-template-schedule';

  const isDropdownDisabled = disabled || disableScheduleButton;

  const dropdownToggle = (
    <Dropdown.Toggle
      split
      variant="success"
      disabled={isDropdownDisabled}
      id="split-button-dropdown-toggle"
      className={`${styles.dropdownToggle} ${isDropdownDisabled ? styles.dropdownToggleDisabled : ''}`}
    >
      <i className={`fas fa-chevron-down fs-7 ${styles.chevronIcon}`} />
    </Dropdown.Toggle>
  );

  return (
    <Dropdown as={ButtonGroup}>
      <Button
        variant="success"
        onClick={handlePrimaryClick}
        disabled={disabled || (isSchedule && !isScheduleComplete)}
        className={`${styles.primaryButton}`}
        id={buttonId}
      >
        {buttonText}
      </Button>
      {isDropdownDisabled ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{t('notifications.already_scheduled')}</Tooltip>}
        >
          <span>{dropdownToggle}</span>
        </OverlayTrigger>
      ) : (
        dropdownToggle
      )}
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={handleSelect}
          id="split-button-dropdown-item"
          disabled={isDropdownDisabled}
        >
          {isSchedule ? primaryText : 'Schedule'}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SplitButtonDropdown;
