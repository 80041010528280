import { Form, InputGroup } from '@valid-eval/shared-react-components';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { required as requiredVal } from 'components/FormFields/validations';

export type TextAreaProps = {
  after?: React.ReactNode;
  before?: React.ReactNode;
  id?: string;
  label?: string;
  name: string;
  placeholder?: string;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  transform?(value: string): string;
  disabled?: boolean;
  required?: boolean;
  helpText?: string;
  className?: string;
  spellCheck?: boolean;
  rows?: number;
};

const TextArea = ({
  after,
  before,
  id,
  label,
  name,
  placeholder,
  rules,
  transform = (value: string) => value,
  disabled,
  required,
  helpText,
  className,
  spellCheck,
  rows = 3,
}: TextAreaProps) => {
  const { control, formState } = useFormContext();

  const showValidation =
    !!formState?.touchedFields?.[name] || !!formState?.dirtyFields?.[name] || formState.isSubmitted;

  const isInvalid = !!formState?.errors?.[name] && showValidation;

  const isValid = !formState?.errors?.[name] && showValidation;

  rules = rules || {};
  rules = required ? { ...rules, validate: { required: requiredVal, ...rules.validate } } : rules;

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value, ...fieldProps } }) => (
          <Form.Group className="mb-2">
            {label && (
              <Form.Label className="w-100" htmlFor={id || name}>
                {label}
              </Form.Label>
            )}
            {helpText && (
              <p className="d-print-none text-pre-wrap">
                <Form.Text muted>{helpText}</Form.Text>
              </p>
            )}
            <InputGroup hasValidation>
              {before}
              <Form.Control
                id={id || name}
                as="textarea"
                onChange={(value) => onChange(transform(value.target.value))}
                placeholder={placeholder}
                value={value}
                isInvalid={isInvalid}
                isValid={isValid}
                disabled={disabled}
                className={className}
                spellCheck={spellCheck}
                rows={rows}
                {...fieldProps}
              />
              {after}
              {isInvalid && (
                <Form.Control.Feedback type="invalid">
                  {String(formState.errors[name]?.message)}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>
        )}
      />
    </>
  );
};

export default TextArea;
