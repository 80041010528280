import { Col, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import Styles from './Heat.module.scss';
import ScorePicker from './ScorePicker';

type ScorePickerFooterProps = {
  grades: any;
  isDimesionNotesOpen: boolean;
  isQualitativeMode: boolean;
  labelClassName: string;
  onChange: (score: number) => void;
  qualitativeMethodology: any;
  readOnly: boolean;
  score: number;
  validScores: any;
};

const ScorePickerFooter = ({
  score,
  labelClassName,
  isDimesionNotesOpen,
  ...rest
}: ScorePickerFooterProps) => {
  return (
    <Row className="m-0 p-0 pb-2 mb-1 flex-nowrap align-items-end">
      <Col
        className={cx(
          Styles.Subdimension,
          Styles.FooterLabel,
          'fw-bold text-black01 text-end d-flex align-items-center',
        )}
        xs="auto"
      >
        <span className="flex-grow-1 text-end">
          {rest.isQualitativeMode ? 'My Rating' : 'My score'}
        </span>
      </Col>
      <Col className="flex-grow-1 p-1">
        <ScorePicker value={score} isDimesionNotesOpen={isDimesionNotesOpen} {...rest} />
      </Col>
    </Row>
  );
};

export default ScorePickerFooter;
