import { Button, Col, Form, Row } from '@valid-eval/shared-react-components';
import { Map } from 'immutable';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';

import Checkbox from 'components/Form/Checkbox';
import Email from 'components/Form/Email';
import TextArea from 'components/Form/TextArea';
import GenericModal from 'components/GenericModal';
import { success } from 'data/actions/notifications';
import { createIntro, fromIntros, updateIntro } from 'data/features/intros';
import { Intro } from 'data/features/introsTypes';
import { getCurrentUser } from 'data/reducers';
import { useShowErrorOnRejectedWithValue } from 'utils/hooks/useShowErrorOnRejectedWithValue';

type IntroFormValues = {
  content: string;
  email: string;
  isDefault: boolean;
};

type IntroFormModalProps = {
  teamId: string;
  phaseId: string;
  judgeId: string;
  intro?: Intro;
  show: boolean;
  onClose: () => void;
};

const mapStateToProps = (state: Map<string, any>) => ({
  defaultContent: fromIntros.getDefaultContent(state?.toJS()),
  user: getCurrentUser(state)?.toJS(),
});

const mapDispatchToProps = { createIntro, updateIntro, success };

const connector = connect(mapStateToProps, mapDispatchToProps);

const IntroFormModal = ({
  show,
  onClose,
  intro,
  user,
  teamId,
  phaseId,
  defaultContent,
  createIntro,
  success,
  updateIntro,
}: IntroFormModalProps & ConnectedProps<typeof connector>) => {
  const showErrorOnRejectedWithValue = useShowErrorOnRejectedWithValue();
  const form = useForm<IntroFormValues>({
    defaultValues: {
      content: intro?.content ?? '',
      email: intro?.email ?? user.email ?? '',
      isDefault: intro?.isDefault ?? true,
    },
  });

  useEffect(() => {
    form.setValue('email', intro?.email ?? user.email ?? '');
    form.setValue('content', intro?.content ?? defaultContent ?? '');
    form.setValue('isDefault', intro?.isDefault ?? true);
  }, [intro, user, defaultContent]);

  const onSubmit = async (data: IntroFormValues) => {
    let promise;
    if (intro) {
      promise = updateIntro({
        intro: {
          ...intro,
          ...data,
        },
        teamId,
        phaseId,
      });
    } else {
      promise = createIntro({
        intro: data,
        teamId,
        phaseId,
      });
    }

    const result = showErrorOnRejectedWithValue(await promise);

    if (!result) return;

    success('The intro has been saved');
    onClose();
  };

  const judgeName = intro?.judge?.name || `${user?.first_name} ${user?.last_name}`;

  return (
    <GenericModal
      show={show}
      title="Introduce Me"
      body={
        <FormProvider {...form}>
          <Form id="intro-form" className="px-3 pt-3" onSubmit={form.handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Col>
                <strong>{judgeName}</strong>
              </Col>
              <Col>
                <label htmlFor="email">
                  <strong>Email</strong>
                </label>
                <Email name="email" required />
              </Col>
            </Row>
            <Row className="mb-3">
              <label htmlFor="email">
                <strong>Intro from you, visible to Selection Committee</strong>
              </label>
              <TextArea name="content" required rows={8} />
            </Row>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-grow-1">
                <Checkbox name="isDefault" isSwitch showValidationState={false} />
                <span>
                  Save as my default <strong>Introduce Me</strong> message
                </span>
              </div>
              <div>
                <Button variant="link" className="me-2" onClick={onClose}>
                  Cancel
                </Button>
                <Button variant="success" type="submit">
                  Send
                </Button>
              </div>
            </div>
          </Form>
        </FormProvider>
      }
      onCancel={onClose}
      onConfirm={onClose}
      confirmButton={null}
      cancelButton={null}
    />
  );
};

export default connector(IntroFormModal);
