import { CellContext } from '@tanstack/react-table';
import { Button, OverlayTrigger, Tooltip } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import { dateInTimeZone, isNavigator } from 'utils'; // Import dateInTimeZone
import Styles from './Table.module.scss';
import { JudgeInvitation } from './types';

export const Invitation = ({ row }: CellContext<JudgeInvitation, any>) => {
  const invitation = row.original;
  let label = <span className="label label-default">PENDING</span>;
  if (invitation.accepted) label = <span className="label label-success">ACCEPTED</span>;
  else if (invitation.declined) label = <span className="label label-danger">DECLINED</span>;
  else if (invitation.has_expired)
    label = (
      <span className="label label-danger">
        EXPIRED <i className="fa-solid fa-bell-exclamation" />
      </span>
    );

  return <td className={cx(Styles.StatusColumn, 'text-center')}>{label}</td>;
};

export const Actions = ({ row, table }: CellContext<JudgeInvitation, number>) => {
  const meta = table.options.meta as any;
  const invitation = row.original;

  const resendTooltip = (
    <Tooltip>
      <strong>Resend invitation</strong>
    </Tooltip>
  );

  const deleteTooltip = (
    <Tooltip>
      <strong>Delete invitation</strong>
    </Tooltip>
  );

  return (
    <td className={cx('text-center', Styles.ActionsColumn)}>
      <div style={{ position: 'relative' }}>
        <OverlayTrigger placement="top" overlay={resendTooltip}>
          <Button
            id={`resend-invitation-${invitation.id}`}
            className={cx('fa-solid fa-paper-plane btn-sm', 'favFont')}
            variant="resend"
            disabled={invitation.accepted || isNavigator()}
            onClick={() => meta.onResend(invitation.id)}
          />
        </OverlayTrigger>

        <OverlayTrigger placement="top" overlay={deleteTooltip}>
          <Button
            id={`delete-invitation-${invitation.id}`}
            className={cx('fa-regular fa-trash-can ms-2 btn-sm', 'favFont')}
            variant="danger"
            disabled={invitation.accepted || isNavigator()}
            onClick={() => meta.onDelete(invitation.id)}
          />
        </OverlayTrigger>
      </div>
    </td>
  );
};

export const LastDateSentCell = ({ row, table }: CellContext<JudgeInvitation, any>) => {
  const invitation = row.original;
  const meta = table.options.meta as any;

  const handleScheduledClick = () => {
    meta.onScheduledClick?.(invitation.scheduled_notification_id);
  };

  if (invitation.send_at && !invitation.delivered_at) {
    return (
      <td className={cx(Styles.SmallerText, 'text-center')}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              <strong>Judge Signup Reminder</strong>
            </Tooltip>
          }
        >
          <Button
            variant="link"
            className={cx(Styles.SmallerText, 'p-0')}
            onClick={handleScheduledClick}
            id={`cancel-${invitation?.batch_id}`}
          >
            <small>
              Scheduled
              <br />
              <span className="text-primary">{dateInTimeZone(invitation.send_at)}</span>
            </small>
          </Button>
        </OverlayTrigger>
      </td>
    );
  } else {
    return (
      <td className={cx(Styles.SmallerText, 'text-center')}>
        <small>{invitation.last_sent_at ? dateInTimeZone(invitation.last_sent_at) : 'N/A'}</small>
      </td>
    );
  }
};
