import { Button, ButtonGroup } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { Children, cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import { matchPath, Navigate } from 'react-router';

import Intros from 'components/IntrosV2/Intros';
import OverallNotes from 'components/NotesV2/OverallNotes';
import EvaluationSelector from 'components/Scores/EvaluationSelector';
import ScoringSubNavigation from 'components/ScoringSubNavigation';
import { initializeOrganizerResultDetails, showEvaluationForTeam } from 'data/actions/evaluations';
import { exportPDF } from 'data/actions/events';
import {
  getCurrentUser,
  getEvaluationFromURL,
  getEvaluationScores,
  getEvaluationScoresAsOptions,
  getEvent,
  getIsLoadingEvaluations,
  getIsLoadingEvaluationScores,
  getPhase,
  getPhaseNavigatorsViewResultsEnabled,
  getPhaseSummary,
  getRubric,
  getSelectedPhaseIdForCurrentEvent,
  getSelectOptionsForTeamsWithCompletedEvaluations,
  getSelfScoreForPhase,
  getTeamById,
  getTeamDocumentsFromEvaluationSelectedInURL,
  getTeamIdFromEvaluationSelectedInURL,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { isNavigator, isTeamLead } from 'utils';
import { organizerNav } from 'utils/urls';

import PDFHeader from './components/PDFHeader';
import ResultsNav from './components/ResultsNav';
import ConfirmPrintPDFForTeamsModal from './ConfirmPrintPDFForTeamsModal';
import Styles from './Results.module.scss';

const SCORES = 0;
const HEATMAP = 1;
const ORGANIZER_USER = true;

class OrganizerResultDetails extends Component {
  state = { confirmPrintPDFForTeams: false };

  componentDidMount() {
    this.props.initializeOrganizerResultDetails(
      this.props.params.evaluation_id,
      this.props.selectedPhaseId,
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPhaseId !== this.props.selectedPhaseId) {
      this.props.showEvaluationForTeam(
        nextProps.selectedTeamId,
        nextProps.selectedPhaseId,
        this.props.params.event_id,
        ORGANIZER_USER,
        this.props.location,
        this.props.navigate,
      );
    }
  }

  handleSelectJudgeComment = (scoreId) => this.handleScoreChange({ value: scoreId });

  handleTeamChange = (team) => {
    this.props.showEvaluationForTeam(
      team.id,
      this.props.selectedPhaseId,
      this.props.params.event_id,
      ORGANIZER_USER,
      this.props.location,
      this.props.navigate,
    );
  };

  handlePageChange(page) {
    const { event_id, evaluation_id } = this.props;
    const { scores, heatmap } = organizerNav;
    const url = page ? heatmap(event_id, evaluation_id) : scores(event_id, evaluation_id);

    this.props.navigate(url);
  }

  handleScoreChange = (option) => {
    const { event_id, evaluation_id } = this.props;
    const { score, scores } = organizerNav;
    const url =
      option && option.value
        ? score(event_id, evaluation_id, option.value)
        : scores(event_id, evaluation_id);

    this.props.navigate(url);
  };

  handleSelectNextScore = () => {
    const selected = this.props.score_id;
    const index =
      selected === null
        ? 0
        : this.props.scoreAsOptions.findIndex((option) => option.value === selected) + 1;

    this.handleScoreChange(this.props.scoreAsOptions[index]);
  };

  handleSelectPreviousScore = () => {
    const selected = this.props.score_id;
    const index =
      selected === null
        ? 0
        : this.props.scoreAsOptions.findIndex((option) => option.value === selected) - 1;

    this.handleScoreChange(this.props.scoreAsOptions[index]);
  };
  showScores = this.handlePageChange.bind(this, SCORES);
  showHeatmap = this.handlePageChange.bind(this, HEATMAP);

  onClickPrint = (event_id, evaluation_id, isUnredacted, displayDetails) => {
    if (!isUnredacted) this.setState({ confirmPrintPDFForTeams: true });
    else this._printPDF(event_id, evaluation_id, isUnredacted, displayDetails);
  };

  _printPDF = (event_id, evaluation_id, isUnredacted, displayDetails) => {
    this.setState({ confirmPrintPDFForTeams: false });
    const { team, event, phase, exportPDF, user } = this.props;
    const url = organizerNav.printScoresAndHeatmap(
      event_id,
      evaluation_id,
      isUnredacted,
      displayDetails,
    );

    exportPDF(
      url,
      user.get('email'),
      event.get('name'),
      team.get('id'),
      team.get('name'),
      phase.get('name'),
      user.get('id'),
      event.get('id'),
      phase.get('id'),
    );
  };

  render() {
    const {
      selectedTeamId,
      availableTeams,
      evaluation,
      event_id,
      event,
      evaluation_id,
      score_id,
      loadingEvaluations,
      selectedPhaseId,
      selfScore,
      rubric,
      phaseSummary,
      scoreAsOptions,
      evaluationScores,
      team,
      phase,
      evaluationIsLoading,
      canAccessResults,
      location,
    } = this.props;

    const isQualitativeMode = event.get('judging_ux_mode') === 'qualitative_only_mode';

    const evaluationDoesNotBelongToSelectedPhase =
      evaluation && evaluation.get('phase_id') !== selectedPhaseId;

    const isIndex = !!matchPath(
      organizerNav.scores(event_id, evaluation_id).replace(/\?.+/gi, ''),
      location.pathname,
    );
    const isHeatmap = !!matchPath(
      organizerNav.heatmap(event_id, evaluation_id).replace(/\?.+/gi, ''),
      location.pathname,
    );

    const noScores = evaluationScores.length === 0;
    const loadingScoringSubNavigation = !availableTeams.length && loadingEvaluations;
    const showExportButton =
      !evaluationDoesNotBelongToSelectedPhase && !noScores && !loadingScoringSubNavigation;

    if (phase && !canAccessResults) return <Navigate to={organizerNav.status(event_id)} />;

    if (isQualitativeMode && isTeamLead() && isIndex) {
      return <Navigate to={organizerNav.heatmap(event_id, evaluation_id)} replace />;
    }

    const selectedScore = evaluationScores.find((s) => s?.get('id') === score_id)?.toJS();
    const judgeRedactedNames = Object.fromEntries(
      evaluationScores.map((s) => [
        s.get('judge'),
        scoreAsOptions.find((o) => o.value === s?.get('id'))?.label,
      ]),
    );

    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <>
        <ResultsNav eventId={event_id} details />
        <PDFHeader event={event} phase={phase} />

        <h1 className={cx('mb-3 mt-5 d-print-none', Styles.PhaseName)}>
          {phase?.get('name')} Results
        </h1>
        <ScoringSubNavigation
          hideRank={isQualitativeMode && isTeamLead()}
          teams={availableTeams || []}
          selectedId={selectedTeamId}
          onTeamChange={this.handleTeamChange}
          eventId={event_id}
          documents={this.props.teamDocuments}
          showExportButton={showExportButton}
          onClickPrint={(isUnredacted) => () =>
            this.onClickPrint(event_id, evaluation_id, isUnredacted, false)
          }
        />

        {!loadingScoringSubNavigation &&
        !evaluationIsLoading &&
        !loadingEvaluations &&
        (evaluationDoesNotBelongToSelectedPhase || noScores) ? null : (
          <>
            <div className={cx('d-print-none d-flex align-items-center flex-wrap', Styles.Buttons)}>
              <ButtonGroup className={'mt-4 mb-3 me-3'} aria-label="Results section">
                {isTeamLead() && isQualitativeMode ? null : (
                  <Button
                    data-test-id="results-navigation-scores-button"
                    variant={isIndex ? 'primary' : 'default'}
                    onClick={this.showScores}
                  >
                    Scores
                  </Button>
                )}
                <Button
                  data-test-id="results-navigation-heatmap-button"
                  variant={isHeatmap ? 'primary' : 'default'}
                  onClick={this.showHeatmap}
                >
                  Heatmap
                </Button>
              </ButtonGroup>

              <EvaluationSelector
                phaseIndividualScoresEnabled={true}
                options={scoreAsOptions}
                value={score_id}
                onChange={this.handleScoreChange}
                nextEvaluation={this.handleSelectNextScore}
                previousEvaluation={this.handleSelectPreviousScore}
                selectedId={selectedTeamId}
                onTeamChange={this.handleTeamChange}
                eventId={event_id}
                documents={this.props.teamDocuments?.toJS?.() || this.props.teamDocuments}
                showExportButton={showExportButton}
                onClickPrint={({ isUnredacted, displayDetails }) =>
                  () =>
                    this.onClickPrint(event_id, evaluation_id, isUnredacted, displayDetails)
                  }
                isOrganizer={!isTeamLead()}
              />
              <ConfirmPrintPDFForTeamsModal
                show={this.state.confirmPrintPDFForTeams}
                onCancel={() => this.setState({ confirmPrintPDFForTeams: false })}
                onConfirm={() => this._printPDF(event_id, evaluation_id, false, false)}
                isForSelectionCommittee
              />
            </div>
            <div>
              {cloneElement(Children.only(this.props.children), {
                selectedPhaseId,
                evaluation,
                selfScore,
                phaseSummary,
                rubric,
                scores: evaluationScores,
                showHeatmap: isHeatmap,
                showScores: isIndex,
                selectedTeamId,
                score_id,
                event_id,
                event,
              })}
            </div>

            {!team || !phase || !evaluation ? null : (
              <>
                <OverallNotes
                  isOrganizerScreen
                  teamId={team.get('id')}
                  phaseId={phase.get('id')}
                  judgeIdFilter={selectedScore?.judge}
                  judgeRedactedNames={judgeRedactedNames}
                  redacted={!location.search.includes('unredacted')}
                />
                <Intros
                  isOrganizerScreen
                  phaseId={phase.get('id')}
                  teamId={team.get('id')}
                  judgeIdFilter={selectedScore?.judge}
                />
              </>
            )}
            <a
              id="print-link"
              style={{ float: 'right', opacity: 0.1 }}
              onClick={() =>
                this.props.navigate(
                  organizerNav.printScoresAndHeatmap(event_id, evaluation_id, true),
                )
              }
            >
              .
            </a>
          </>
        )}
      </>
    );
  }
}

OrganizerResultDetails.displayName = 'OrganizerResultDetails';

export default withRouter(
  connect(
    (state, ownProps) => {
      const { evaluation_id, event_id, score_id } = ownProps.params;
      const selectedPhaseId = getSelectedPhaseIdForCurrentEvent(state, ownProps);
      const evaluation = getEvaluationFromURL(state, ownProps);

      const selectedTeamId = getTeamIdFromEvaluationSelectedInURL(state, ownProps);
      const selfScore =
        selectedTeamId && getSelfScoreForPhase(state, selectedTeamId, selectedPhaseId);

      const availableTeams = getSelectOptionsForTeamsWithCompletedEvaluations(state, ownProps);

      return {
        selectedPhaseId,
        selfScore,
        selectedTeamId,
        availableTeams,
        evaluation,
        evaluation_id,
        evaluationIsLoading: getIsLoadingEvaluationScores(state, evaluation_id),
        event_id,
        score_id: score_id || null,
        scoreAsOptions: getEvaluationScoresAsOptions(state, ownProps),
        evaluationScores: getEvaluationScores(state, ownProps),
        loadingEvaluations: getIsLoadingEvaluations(state),
        phaseSummary: getPhaseSummary(state, selectedPhaseId),
        rubric: getRubric(state, selectedPhaseId),
        teamDocuments: getTeamDocumentsFromEvaluationSelectedInURL(state, ownProps),
        event: getEvent(state, event_id),
        team: getTeamById(state, selectedTeamId),
        phase: getPhase(state, selectedPhaseId),
        user: getCurrentUser(state),
        canAccessResults: !isNavigator() || getPhaseNavigatorsViewResultsEnabled(state, ownProps),
      };
    },
    { showEvaluationForTeam, initializeOrganizerResultDetails, exportPDF },
  )(OrganizerResultDetails),
);
