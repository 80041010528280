import { Button, ButtonGroup } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { Map } from 'immutable';
import { Children, cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';

import FeedbackUnavailable from 'components/feedbackUnavailable';
import Intros from 'components/IntrosV2/Intros';
import Loading from 'components/Loading';
import OverallNotes from 'components/NotesV2/OverallNotes';
import EvaluationSelector from 'components/Scores/EvaluationSelector';
import { load as loadContent } from 'data/actions/contents';
import { showEvaluationForTeam } from 'data/actions/evaluations';
import { exportPDF } from 'data/actions/events';
import { changeTeamSelectedPhase } from 'data/actions/phases';
import {
  getCurrentUser,
  getEvaluationByPhaseAndTeam,
  getEvaluationScoresAsOptionsByPhaseAndTeam,
  getEvaluationScoresByPhaseAndTeam,
  getFirstActivePhaseIdForCurrentTeam,
  getIsLoadingEvaluations,
  getIsLoadingEvaluationScoresByPhaseAndTeam,
  getPhase,
  getPhasesForCurrentTeam,
  getPhaseSummary,
  getRubric,
  getSelectedPhasePerformanceEnabledForCurrentTeam,
  getSelfScoreForPhase,
  getTeamClassificationInEvent,
  getTeamWithCategoryNameFromURL,
  getUnavailableScoreContentForTeam,
  getUserFullName,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { teamNav } from 'utils/urls';

const SCORES = 1;
const HEATMAP = 2;

class ResultDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: SCORES,
      routed: false,
    };
  }

  componentDidMount() {
    const {
      params: { team_id, event_id },
      selectedPhaseId,
      showEvaluationForTeam,
      loadContent,
      phasesForCurrentTeam,
      firstActivePhaseId,
      changeTeamSelectedPhase,
      query,
    } = this.props;

    loadContent(event_id, 'Team', 'Scores Unavailable');

    let phaseId = selectedPhaseId;

    if (query.phase_id && query.phase_id !== selectedPhaseId) {
      phaseId = query.phase_id;
      changeTeamSelectedPhase(team_id, query.phase_id);
    } else if (
      (phasesForCurrentTeam.length <= 1 && firstActivePhaseId !== selectedPhaseId) ||
      !selectedPhaseId
    ) {
      phaseId = firstActivePhaseId;
      changeTeamSelectedPhase(team_id, firstActivePhaseId);
    }

    if (phaseId) {
      showEvaluationForTeam(team_id, phaseId, event_id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { team_id, event_id } = this.props.params;
    const samePhase = nextProps.selectedPhaseId === this.props.selectedPhaseId;
    const performanceEnabledToggled =
      nextProps.phasePerformanceEnabled !== this.props.phasePerformanceEnabled;

    if (!samePhase) {
      this.props.showEvaluationForTeam(team_id, nextProps.selectedPhaseId, event_id);
      this.handleScoreChange({ value: undefined });
    }

    if (performanceEnabledToggled) {
      this.routeToHome(nextProps.phasePerformanceEnabled, !this.state.routed);
      this.setState({ routed: true });
    }

    this.updateActiveTab(nextProps.phasePerformanceEnabled);
  }

  handlePageChange(page) {
    const {
      params: { event_id, team_id },
    } = this.props;
    const { scores, heatmap } = teamNav;
    const urls = [scores, heatmap];
    const url = page && urls[page - 1](event_id, team_id);

    if (url && !window.location.pathname.includes('/print')) this.props.navigate(url);
  }

  handleScoreChange = (option) => {
    const { event_id, team_id } = this.props.params;
    const { score, scores } = teamNav;
    const url =
      option && option.value ? score(event_id, team_id, option.value) : scores(event_id, team_id);

    if (!window.location.pathname.includes('/print')) this.props.navigate(url);
  };

  handleSelectNextScore = () => {
    const selected = this.props.params.score_id;
    const index = !selected
      ? 0
      : this.props.scoreAsOptions.findIndex((option) => option.value === selected) + 1;

    this.handleScoreChange(this.props.scoreAsOptions[index]);
  };

  handleSelectPreviousScore = () => {
    const selected = this.props.params.score_id;
    const index = !selected
      ? 0
      : this.props.scoreAsOptions.findIndex((option) => option.value === selected) - 1;

    this.handleScoreChange(this.props.scoreAsOptions[index]);
  };

  handleSelectJudgeComment = (scoreId) => this.handleScoreChange({ value: scoreId });

  showScores = this.handlePageChange.bind(this, SCORES);
  showHeatmap = this.handlePageChange.bind(this, HEATMAP);

  handleRecommendationSubmit = (values) => {
    const text = values.get('recommendation');
    const author = values.get('author');
    const {
      user,
      params: { team_id },
    } = this.props;
    const name = author || getUserFullName(user);
    const authorId = !!author ? null : Number(team_id);
    this.props.create(name, text, Number(team_id), authorId);
  };

  updateActiveTab = (phasePerformanceEnabled) => {
    const {
      params: { team_id, event_id },
      query,
    } = this.props;

    const isScores = !!matchPath(teamNav.scores(event_id, team_id), this.props.location.pathname);
    const urlPhaseId = query.phase_id;

    if (urlPhaseId) {
      this.showScores();
    } else if (isScores && !phasePerformanceEnabled) {
      this.showHeatmap();
    }
  };

  routeToHome = (phasePerformanceEnabled, forceRoute = false) => {
    const {
      params: { team_id, event_id },
    } = this.props;
    const isScores = !!matchPath(teamNav.scores(event_id, team_id), this.props.location.pathname);
    const isHeatmap = !!matchPath(teamNav.heatmap(event_id, team_id), this.props.location.pathname);

    if (!forceRoute && ((isScores && phasePerformanceEnabled) || isHeatmap)) {
      return;
    }

    if (phasePerformanceEnabled) {
      this.showScores();
      return;
    }

    this.showHeatmap();
  };

  onClickPrint = () => {
    const { team, event, evaluation, phase, exportPDF, user } = this.props;

    const url = teamNav.printScoresAndHeatmap(
      event.get('id'),
      team.get('id'),
      evaluation.get('id'),
    );

    exportPDF(
      url,
      user.get('email'),
      event.get('name'),
      team.get('id'),
      team.get('name'),
      phase.get('name'),
      user.get('id'),
      event.get('id'),
      phase.get('id'),
    );
  };

  renderUnavailable(content) {
    return <FeedbackUnavailable content={content} />;
  }

  renderChildren(
    phaseIndividualScoresEnabled,
    phasePerformanceEnabled,
    isIndex,
    isHeatmap,
    scoreAsOptions,
    score_id,
    user,
    evaluation,
    selfScore,
    evaluationScores,
    phaseSummary,
    rubric,
    teamId,
    eventId,
  ) {
    return (
      <>
        <div className={cx('d-print-none d-flex align-items-center flex-wrap')}>
          <ButtonGroup className={'mt-4 mb-3 me-3'} aria-label="Results section">
            {phasePerformanceEnabled && (
              <Button
                data-test-id="results-navigation-scores-button"
                variant={isIndex ? 'primary' : 'default'}
                onClick={this.showScores}
              >
                Scores
              </Button>
            )}
            <Button
              data-test-id="results-navigation-heatmap-button"
              variant={isHeatmap ? 'primary' : 'default'}
              onClick={this.showHeatmap}
            >
              Heatmap
            </Button>
          </ButtonGroup>

          <div className="float-start" style={{ flex: 1 }}>
            <EvaluationSelector
              options={scoreAsOptions}
              value={score_id}
              onChange={this.handleScoreChange}
              nextEvaluation={this.handleSelectNextScore}
              previousEvaluation={this.handleSelectPreviousScore}
              phaseIndividualScoresEnabled={phaseIndividualScoresEnabled}
              onClickPrint={() => this.onClickPrint()}
            />
          </div>
        </div>

        <div>
          {cloneElement(Children.only(this.props.children), {
            user,
            evaluation,
            selfScore,
            score_id,
            scores: evaluationScores,
            phaseSummary,
            rubric,
            phasePerformanceEnabled,
            phaseIndividualScoresEnabled,
            teamId,
            eventId,
          })}
        </div>
      </>
    );
  }

  render() {
    const {
      evaluation,
      evaluationScores,
      loadingEvaluation,
      params: { team_id, event_id, score_id },
      phase,
      phasePerformanceEnabled,
      phaseSummary,
      rubric,
      scoreAsOptions,
      selfScore,
      unavailableScoreContent,
      user,
      loadingScoresForThisEvaluation,
    } = this.props;

    const phaseFeedbackEnabled = phase && phase.get('feedback_enabled');
    const phaseIndividualScoresEnabled = phase && phase.get('individual_scores_enabled');

    const isIndex = !!matchPath(teamNav.scores(event_id, team_id), this.props.location.pathname);
    const isHeatmap =
      !!matchPath(teamNav.heatmap(event_id, team_id), this.props.location.pathname) ||
      (isIndex && !phasePerformanceEnabled);

    if (loadingEvaluation || loadingScoresForThisEvaluation) {
      return <Loading />;
    }

    const selectedScore = evaluationScores.find((s) => s?.get('id') === score_id)?.toJS();

    const judgeRedactedNames = Object.fromEntries(
      evaluationScores.map((s) => {
        const judgeId = Map.isMap(s?.get('judge')) ? s?.get('judge')?.get('id') : s?.get('judge');
        return [judgeId, scoreAsOptions.find((o) => o.value === s?.get('id'))?.label];
      }),
    );

    /*
      ---- README ----  
      I added the evaluationScores.length === 0; condition so we show the Feedback Unavailable content
      when the are no scores for this team... Not sure if it's better to simply show
      the box plot saying that the # of scoring judges is 0
      ---- README ----
    */
    const phaseFeedbackDisabledOrNoScoresRecorded =
      !evaluation || !phaseFeedbackEnabled || evaluationScores.length === 0;

    return (
      <>
        {phaseFeedbackDisabledOrNoScoresRecorded
          ? this.renderUnavailable(unavailableScoreContent)
          : this.renderChildren(
              phaseIndividualScoresEnabled,
              phasePerformanceEnabled,
              isIndex,
              isHeatmap,
              scoreAsOptions,
              score_id,
              user,
              evaluation,
              selfScore,
              evaluationScores,
              phaseSummary,
              rubric,
              team_id,
              event_id,
            )}
        {!phaseFeedbackDisabledOrNoScoresRecorded && evaluation && (
          <>
            <OverallNotes
              teamId={evaluation.get('team')}
              phaseId={evaluation.get('phase_id')}
              judgeIdFilter={selectedScore?.judge?.id}
              judgeRedactedNames={judgeRedactedNames}
              isTeamScreen
              redacted
            />
            <Intros
              teamId={evaluation.get('team')}
              phaseId={evaluation.get('phase_id')}
              judgeIdFilter={selectedScore?.judge?.id}
              isTeamScreen
            />
          </>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const {
        params: { team_id, event_id },
        selectedPhaseId,
      } = ownProps;

      const phase = getPhase(state, selectedPhaseId);

      const evaluation = getEvaluationByPhaseAndTeam(state, selectedPhaseId, team_id);
      const loadingEvaluation = getIsLoadingEvaluations(state);

      const team = getTeamWithCategoryNameFromURL(state, ownProps);

      const evaluationScores = getEvaluationScoresByPhaseAndTeam(state, ownProps);

      const selfScore = getSelfScoreForPhase(state, team_id, selectedPhaseId);

      const phasePerformanceEnabled = getSelectedPhasePerformanceEnabledForCurrentTeam(
        state,
        ownProps,
      );

      const unavailableScoreContent = getUnavailableScoreContentForTeam(state, ownProps);

      return {
        evaluation,
        evaluationScores,
        loadingEvaluation,
        loadingTeam: !team,
        phase,
        phasePerformanceEnabled,
        phaseSummary: getPhaseSummary(state, selectedPhaseId),
        rubric: getRubric(state, selectedPhaseId),
        scoreAsOptions: getEvaluationScoresAsOptionsByPhaseAndTeam(state, ownProps),
        selfScore,
        team,
        teamClassification: getTeamClassificationInEvent(team_id, event_id)(state),
        unavailableScoreContent,
        user: getCurrentUser(state),
        loadingScoresForThisEvaluation: getIsLoadingEvaluationScoresByPhaseAndTeam(state, ownProps),
        phasesForCurrentTeam: getPhasesForCurrentTeam(state, ownProps),
        firstActivePhaseId: getFirstActivePhaseIdForCurrentTeam(state, ownProps),
      };
    },
    { showEvaluationForTeam, loadContent, changeTeamSelectedPhase, exportPDF },
  )(ResultDetails),
);
