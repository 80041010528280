import { Row } from '@tanstack/react-table';

export function sortStrings(a: Row<any>, b: Row<any>, columnId: string) {
  const strA = (a.getValue(columnId) as string)?.toLowerCase?.() || '';
  const strB = (b.getValue(columnId) as string)?.toLowerCase?.() || '';

  if (!strA && strB) return 1;
  if (strA && !strB) return -1;
  if (!strA && !strB) return 0;

  return strA.localeCompare(strB);
}

export function sortDates(a: Row<any>, b: Row<any>) {
  const dateA = a.original.send_at || a.original.last_sent_at;
  const dateB = b.original.send_at || b.original.last_sent_at;

  const timeA = dateA ? new Date(dateA).getTime() : 0;
  const timeB = dateB ? new Date(dateB).getTime() : 0;

  return timeA - timeB;
}

export function sortProgress(a: Row<any>, b: Row<any>, columnId: string) {
  const numA = (a.getValue(columnId) as { completed: number })?.completed || 0;
  const numB = (b.getValue(columnId) as { completed: number })?.completed || 0;

  if (!numA && numB) return 1;
  if (numA && !numB) return -1;
  if (!numA && !numB) return 0;

  return numA - numB;
}

export function sortLatestMessage(a: Row<any>, b: Row<any>, columnId: string) {
  const dateA = (a.getValue(columnId) as { created_at: Date })?.created_at || null;
  const dateB = (b.getValue(columnId) as { created_at: Date })?.created_at || null;

  if (!dateA && dateB) return 1;
  if (dateA && !dateB) return -1;
  if (!dateA && !dateB) return 0;

  return dateA.getTime() - dateB.getTime();
}
