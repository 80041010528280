import { Button, Form } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Checkbox from 'components/Form/Checkbox';
import TextArea from 'components/Form/TextArea';
import { Note } from 'data/features/notesTypes';

import NoteDelete from './NoteDelete';
import Styles from './NoteForm.module.scss';
import NoteFormWrapper from './NoteFormWrapper';
import { isTeamLead } from 'utils';
import { isGovEnv } from 'config';

export type NoteFormData = {
  id?: string;
  content: string;
  internal: boolean;
};

type NoteFormProps = {
  internalOnly?: boolean;
  note?: Note;
  onCancel?(): void;
  onSubmit(data: NoteFormData): Promise<Note | undefined>;
  saving?: boolean;
  withButton?: boolean;
  compact?: boolean;
  initialShow?: boolean;
};

const NoteForm = ({
  compact,
  internalOnly,
  note,
  onCancel,
  onSubmit,
  saving,
  withButton = true,
  initialShow = false,
}: NoteFormProps) => {
  const form = useForm<NoteFormData>({
    defaultValues: {
      id: note?.id,
      content: note?.content || '',
      internal: note?.type === 'internal' || internalOnly,
    },
  });

  const { id, internal } = form.getValues();

  useEffect(() => {
    if (!withButton) form.setFocus('content');
  }, []);

  // Detects if there is a change at the comment box
  // and asks the user for confirmation before leaving
  // the page.
  // NOTE THE DEFAULT BROWSER MESSAGE CANNOT BE OVERRIDDEN
  useEffect(() => {
    function unloadListener(e: BeforeUnloadEvent) {
      if (form.formState.isValid) {
        const confirmationMessage = '/o';
        e.returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome
      }
      return true;
    }

    window.addEventListener('beforeunload', unloadListener);
    return () => window.removeEventListener('beforeunload', unloadListener);
  }, [form.formState.isValid]);

  // If only internal notes are allowed, set the internal checkbox to true
  const setInternal = () => {
    form.reset();
    if (internalOnly) form.setValue('internal', true);
    form.trigger();
  };

  useEffect(() => {
    setInternal();
  }, [internalOnly]);

  const onShowForm = () => {
    form.setFocus('content');
  };

  const handleSubmit = async (data: NoteFormData) => {
    const result = await onSubmit(data);
    if (!result) return;
    setInternal();
  };

  const disableEditContentForTeamLead = note && isTeamLead() && !note?.isYou;

  return (
    <NoteFormWrapper
      internal={internal}
      withButton={withButton}
      onShowForm={onShowForm}
      initialShow={initialShow}
    >
      <div>
        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(handleSubmit)}>
            <TextArea
              placeholder="Leave your comment here..."
              required
              name="content"
              spellCheck={true}
              className={cx({
                [Styles.TextAreaInternal]: internal,
                [Styles.TextAreaShared]: !internal,
              })}
              disabled={saving || disableEditContentForTeamLead}
            />
            <div className="mt-3 d-flex justify-content-end align-items-center">
              <div className="flex-grow-1">{note && <NoteDelete note={note} />}</div>

              <div
                className={cx('d-flex align-items-center justify-content-end flex-grow-1 ms-4', {
                  'me-2': !compact || !id,
                })}
              >
                <Checkbox
                  disabled={internalOnly || saving}
                  name="internal"
                  showValidationState={false}
                  isSwitch
                />
                <small className={cx('text-muted fw-bold')}>
                  {isGovEnv() ? 'Government-only' : 'Selection committee'}
                </small>
              </div>

              {id && (
                <Button
                  className={cx({
                    'me-2': !compact,
                    'px-3': compact,
                    'py-2': compact,
                  })}
                  variant="link"
                  type="button"
                  disabled={saving}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="success"
                type="submit"
                className={cx({
                  'px-3': compact,
                  'py-2': compact,
                })}
                disabled={saving}
              >
                {id ? 'Save' : 'Add Comment'}
              </Button>
            </div>
          </Form>
        </FormProvider>
      </div>
    </NoteFormWrapper>
  );
};

export default NoteForm;
