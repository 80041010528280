import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, Form } from '@valid-eval/shared-react-components';
import Select from 'react-select';
import moment from 'moment-timezone';
// @ts-expect-error Vite and Rollup doesn't play well with cjs modules
import DateTime from 'react-datetime/dist/react-datetime.umd'; // Import DateTime component
import { useTranslation } from 'react-i18next';
import { dateIsAfter } from 'components/FormFields/validations';

type ScheduleData = {
  timezone: string;
  dateTime?: moment.Moment;
};

type Props = {
  onChange: (isoString: string | null, isValid: boolean) => void;
  minDate?: moment.Moment;
};

function ScheduleInfo({ onChange, minDate }: Props) {
  const { t } = useTranslation();
  const defaultTimezone = moment.tz.guess();
  const [scheduleData, setScheduleData] = useState<ScheduleData>({
    timezone: defaultTimezone,
  });
  const [isDateTimeValid, setIsDateTimeValid] = useState(false);

  const timezoneOptions = useMemo(
    () =>
      moment.tz.names().map((tz) => ({
        value: tz,
        label: `UTC/GMT ${moment.tz(tz).format('Z')} (${tz.replace(/_/g, ' ')})`,
      })),
    [],
  );

  const handleChange = useCallback(
    (field: keyof ScheduleData, value: string | moment.Moment | null) => {
      setScheduleData((prev) => ({
        ...prev,
        [field]: value !== null ? value : prev[field],
      }));
    },
    [],
  );

  useEffect(() => {
    const { timezone, dateTime } = scheduleData;

    if (timezone && dateTime) {
      const parsedDateTime = moment(dateTime);
      if (!parsedDateTime.isValid()) {
        onChange(null, false);
        setIsDateTimeValid(false);
        return;
      }
      const scheduledDateTime = moment.tz(parsedDateTime.format('YYYY-MM-DD HH:mm:ss'), timezone);
      const scheduledDateTimeUTC = scheduledDateTime.clone().utc();
      const isValid =
        (!minDate || dateIsAfter(minDate)(scheduledDateTimeUTC.toDate()) === undefined) &&
        scheduledDateTimeUTC.isAfter(moment.utc());

      setIsDateTimeValid(isValid);
      onChange(scheduledDateTimeUTC.toISOString(), isValid);
    } else {
      onChange(null, false);
      setIsDateTimeValid(false);
    }
  }, [scheduleData, onChange, minDate]);

  return (
    <Row>
      <Col md={7}>
        <Form.Group>
          <Form.Label>
            <b>{t('notifications.select_date')}</b>
          </Form.Label>
          <DateTime
            inputProps={{
              id: 'scheduleDateTime',
              placeholder: t('notifications.select_date'),
              style: { paddingTop: 7, paddingBottom: 6 },
            }}
            timeFormat
            onChange={(date: string | moment.Moment | null) => handleChange('dateTime', date)}
          />
          {scheduleData.dateTime && !isDateTimeValid && (
            <div className="invalid-feedback d-block mt-2">{t('notifications.invalid_date')}</div>
          )}
        </Form.Group>
      </Col>
      <Col md={5}>
        <Form.Group>
          <Form.Label>
            <b>{t('notifications.select_timezone')}</b>
          </Form.Label>
          <Select
            id="timezone-select"
            options={timezoneOptions}
            onChange={(selectedOption) =>
              handleChange('timezone', selectedOption ? selectedOption.value : null)
            }
            value={timezoneOptions.find((option) => option.value === scheduleData.timezone)}
            placeholder="Select Timezone"
            className="m-0 p-0"
          />
        </Form.Group>
      </Col>
    </Row>
  );
}

export default React.memo(ScheduleInfo);
