import { Note, NoteRole } from 'data/features/notesTypes';
import { isNavigator, isTeamLead } from 'utils';

export const getUserRole = (
  isOranizerScreen: boolean = false,
  isTeamScreen: boolean = false,
): NoteRole => {
  let role = isOranizerScreen ? 'Organizer' : 'JudgeProfile';
  role = isTeamScreen ? 'Team' : role;
  role = isNavigator() ? 'Navigator' : role;
  role = isTeamLead() ? 'TeamLead' : role;
  return role as NoteRole;
};

export type SortBy = 'netVotes' | 'dimension';

export const sortByType = (a: Note, b: Note) => {
  if (a.type === 'shared' && b.type === 'internal') return 1;
  if (a.type === 'internal' && b.type === 'shared') return -1;
  return 0;
};

export const sortByWriterIsOrganizer = (a: Note, b: Note) => {
  if (a.writerIsOrganizer && !b.writerIsOrganizer) return -1;
  if (!a.writerIsOrganizer && b.writerIsOrganizer) return 1;
  return 0;
};

export const sortByNetVotes = (a: Note, b: Note) => {
  if (a.netVotes > b.netVotes) return -1;
  if (a.netVotes < b.netVotes) return 1;
  return 0;
};

export const sortByCreatedAt = (a: Note, b: Note) => {
  const aDate = new Date(a.createdAt).getTime();
  const bDate = new Date(b.createdAt).getTime();
  if (aDate > bDate) return -1;
  if (aDate < bDate) return 1;
  return 0;
};

export const sortByDimensionOrder = (a: Note, b: Note) => {
  const aOrder = a.dimensionOrder ?? 0;
  const bOrder = b.dimensionOrder ?? 0;
  if (aOrder > bOrder) return 1;
  if (aOrder < bOrder) return -1;
  return 0;
};

export const sortNotes = (notes: Note[], sortBy: SortBy = 'dimension') => {
  if (!notes?.length) return [];

  const byDimension = (a: Note, b: Note) =>
    sortByDimensionOrder(a, b) ||
    sortByType(a, b) ||
    sortByWriterIsOrganizer(a, b) ||
    sortByNetVotes(a, b) ||
    sortByCreatedAt(a, b);

  const byNetVotes = (a: Note, b: Note) =>
    sortByNetVotes(a, b) ||
    sortByType(a, b) ||
    sortByWriterIsOrganizer(a, b) ||
    sortByDimensionOrder(a, b) ||
    sortByCreatedAt(a, b);

  return notes.slice().sort(sortBy === 'dimension' ? byDimension : byNetVotes);
};

export const filterDimensionNotes = (
  note: Note,
  excludeDimensionNotes: boolean,
  dimensionId: string,
) => {
  if (excludeDimensionNotes) return note.dimensionId === null;
  if (dimensionId) return note.dimensionId === dimensionId;
  return true;
};

export const filterNoteByPhaseId = (note: Note, phaseId: string) =>
  phaseId ? note.phaseId === phaseId : true;

export const filterNoteByTeamId = (note: Note, teamId: string) => note.teamId === teamId;

export const filterNoteByJudgeId = (note: Note, judgeId: string) =>
  judgeId ? note.writerId === judgeId : true;

export const filterRedactedNotes = (note: Note, redacted?: boolean) =>
  redacted && note.type === 'internal' ? false : true;

export const filterNotes = (notes: Note[], params: Record<string, any>) => {
  const { judgeIdFilter, redacted, phaseId, teamId, excludeDimensionNotes, dimensionId } = params;
  return notes.filter(
    (note) =>
      filterDimensionNotes(note, excludeDimensionNotes, dimensionId) &&
      filterNoteByPhaseId(note, phaseId) &&
      filterNoteByTeamId(note, teamId) &&
      filterNoteByJudgeId(note, judgeIdFilter) &&
      filterRedactedNotes(note, redacted),
  );
};

export const transformNotes = (notes: Note[], params: Record<string, any>) => {
  const { judgeRedactedNames, redacted } = params;
  return notes.map((note) => {
    let redactedWriter = 'From a Judge';

    if (note.writerIsOrganizer) {
      redactedWriter =
        note.role === 'TeamLead' ? "Team Lead's Comment to Team" : 'From Selection Committee';
    }

    return {
      ...note,
      writer: redacted ? judgeRedactedNames[note.writerId] || redactedWriter : note.writer,
    };
  });
};
