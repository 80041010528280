import { Map } from 'immutable';
import { connect, ConnectedProps } from 'react-redux';

import { success as showSuccess } from 'data/actions/notifications';
import { createNote, fromNotes } from 'data/features/notes';
import { NotePayload } from 'data/features/notesTypes';
import { useShowErrorOnRejectedWithValue } from 'utils/hooks/useShowErrorOnRejectedWithValue';

import NoteForm, { NoteFormData } from './components/NoteForm';
import { getUserRole } from './helpers';

type AddNoteProps = {
  isOrganizerScreen: boolean;
  dimensionId?: string;
  phaseId: string;
  teamId: string;
  compact?: boolean;
  onNoteAdded?(): void;
  withButton?: boolean;
  initialShow?: boolean;
};

const mapStateToProps = (state: Map<string, any>, ownProps: AddNoteProps) => ({
  saving: fromNotes.getIsSaving(state?.toJS()),
  canAddInternalNotes: fromNotes.getCanAddInternalNotes(state?.toJS(), ownProps.teamId),
  canAddSharedNotes: fromNotes.getCanAddSharedNotes(state?.toJS(), ownProps.teamId),
});

const mapDispatchToProps = {
  createNote,
  showSuccess,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const AddNote = ({
  createNote,
  isOrganizerScreen = false,
  saving,
  phaseId,
  teamId,
  dimensionId,
  canAddSharedNotes,
  canAddInternalNotes,
  compact,
  showSuccess,
  onNoteAdded,
  withButton = true,
  initialShow = false,
}: AddNoteProps & ConnectedProps<typeof connector>) => {
  const showErrorOnRejectedWithValue = useShowErrorOnRejectedWithValue();

  const handleSubmit = async (data: NoteFormData) => {
    const note: NotePayload = {
      dimensionId: dimensionId,
      content: data.content,
      type: data.internal ? 'internal' : 'shared',
      role: getUserRole(isOrganizerScreen),
    };

    const result = showErrorOnRejectedWithValue(await createNote({ note, phaseId, teamId }));
    if (result) {
      showSuccess('The comment has been saved');
      onNoteAdded?.();
    }
    return result;
  };

  if (!canAddSharedNotes && !canAddInternalNotes) return null;

  return (
    <NoteForm
      initialShow={initialShow}
      onSubmit={handleSubmit}
      saving={saving}
      internalOnly={!canAddSharedNotes}
      compact={compact}
      withButton={withButton}
    />
  );
};

export default connector(AddNote);
