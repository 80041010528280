import { Button, Collapse } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { ReactElement, useEffect } from 'react';

import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import Styles from './NoteForm.module.scss';

type NoteFormWrapperProps = {
  internal: boolean;
  onShowForm: () => void;
  children: ReactElement;
  withButton?: boolean;
  initialShow?: boolean;
};

const NoteFormWrapper = ({
  internal,
  children,
  onShowForm,
  withButton = true,
  initialShow = false,
}: NoteFormWrapperProps) => {
  const [showForm, show, hide] = useBooleanFlag(initialShow);

  useEffect(() => {
    if (showForm) setTimeout(onShowForm, 100);
  }, [showForm]);

  useEffect(() => {
    if (initialShow) show();
    else hide();
  }, [initialShow]);

  if (!withButton) return children;

  return (
    <>
      <div
        className={cx('gap-2', {
          'd-none': showForm,
          'd-grid': !showForm,
        })}
      >
        <Button
          id="add-note-button"
          onClick={show}
          variant="default"
          className={cx('text-start px-3', Styles.NoteFormWrapper, {
            [Styles.TextAreaInternal]: internal,
            [Styles.TextAreaShared]: !internal,
          })}
        >
          <span className="text-muted">Leave your comment here...</span>
        </Button>
      </div>
      <Collapse in={showForm}>{children}</Collapse>
    </>
  );
};

export default NoteFormWrapper;
