import { useState } from 'react';
import { connect } from 'react-redux';

import {
  getCurrentEventIsQualitativeMode,
  getCurrentEventQualitativeMethodology,
  getEventFromURL,
} from 'data/reducers';
import { getValidScoresFromClickedCriteria } from 'data/reducers/scores';
import withRouter from 'routes/withRouter';

import RubricDimension from '../RubricDimension';
import ScoreInfo from '../ScoreInfo';
import Styles from './RubricScoring.module.scss';

const RubricScoring = ({
  children,
  commentsByDimension,
  criterias,
  disabled,
  isQualitativeMode,
  onChangeCriteria,
  onChangeScore,
  onContinueEvaluations,
  qualitativeMethodology,
  rubric,
  score,
  scores,
}) => {
  const grades = rubric.get('grades');
  const dimensions = rubric.get('dimensions');
  const clickedCriteria = score.get('clicked_criteria');
  const scoresCount = scores.filter((value, dimensionId) => value && value !== 0).size;
  const dimensionCount = dimensions.size;
  const [isDimesionNotesOpen, setIsDimensionNotesOpen] = useState({});

  return (
    <div>
      <div className={Styles.container}>
        <div className={Styles.dimensionsContainer}>
          {dimensions.map((dimension, index) => {
            const dimId = String(dimension.get('id'));
            return (
              <RubricDimension
                clickedCriteria={clickedCriteria}
                coloredBackground={index % 2 === 0}
                commentsByDimension={commentsByDimension}
                dimension={dimension}
                grades={grades}
                isDimesionNotesOpen={isDimesionNotesOpen[dimId]}
                isQualitativeMode={isQualitativeMode}
                judgeId={score.get('judge')}
                key={`dim-${dimId}`}
                onChangeCriteria={onChangeCriteria.bind(this, dimId)}
                onChangeScore={onChangeScore.bind(this, dimId)}
                phaseId={score.get('phase_id')}
                qualitativeMethodology={qualitativeMethodology}
                score={scores.get(dimId)}
                selectedCriteria={criterias.get(dimId)}
                setIsDimensionNotesOpen={(value) =>
                  setIsDimensionNotesOpen({ ...isDimesionNotesOpen, [dimId]: value })
                }
                teamId={score.get('team_id')}
                validScores={getValidScoresFromClickedCriteria(grades, dimension, clickedCriteria)}
              />
            );
          })}
          {children}
        </div>
        <div className={Styles.infoContainer}>
          {!disabled && (
            <ScoreInfo
              clicksPercent={score.get('clicks_percent')}
              complete={score.get('complete')}
              completePercent={score.get('complete_percent')}
              criteriaCount={clickedCriteria.count()}
              isDimesionNotesOpen={isDimesionNotesOpen}
              isQualitativeMode={isQualitativeMode}
              onContinue={onContinueEvaluations}
              qualitativeMethodology={qualitativeMethodology}
              scoresCount={scoresCount}
              scoreValue={score.get('score')}
              totalScoresCount={dimensionCount}
            />
          )}
        </div>
      </div>
    </div>
  );
};

RubricScoring.displayName = 'RubricScoring';

export default withRouter(
  connect((state, props) => {
    return {
      event: getEventFromURL(state, props)?.toJS(),
      isQualitativeMode: getCurrentEventIsQualitativeMode(state, props),
      qualitativeMethodology: getCurrentEventQualitativeMethodology(state, props),
    };
  })(RubricScoring),
);
