import { Col, OverlayTrigger, Tooltip } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import debounce from 'lodash/debounce';

import { Map } from 'immutable';
import Styles from './Heat.module.scss';

type CriterionNameProps = {
  subdimensionId: string;
  criterion?: Map<string, any>;
  heat: number;
  hits: number;
  isDimesionNotesOpen: boolean;
  isHeatmap: boolean;
  isSelected: boolean;
  isSelectedByTeam: boolean;
  onChangeCriteria?: (subdimensionId: string, criterionId: string) => void;
  index: number;
};

const HeatRangeMap = {
  'range-1': Styles.Heat1,
  'range-2': Styles.Heat2,
  'range-3': Styles.Heat3,
  'range-4': Styles.Heat4,
};

export const heatRange = (heat: number) => {
  if (!heat || heat === 0) return 'range-1';
  else if (heat <= 0.25) return 'range-2';
  else if (heat <= 0.5) return 'range-3';
  else return 'range-4';
};

const heatmapClass = (range: keyof typeof HeatRangeMap) => {
  return HeatRangeMap[range] || Styles.Heat1;
};

const CriterionName = ({
  subdimensionId,
  criterion,
  heat,
  hits,
  isDimesionNotesOpen,
  isHeatmap,
  isSelected,
  isSelectedByTeam,
  onChangeCriteria,
  index,
}: CriterionNameProps) => {
  const handleClick = debounce(
    () => {
      !isDimesionNotesOpen && criterion && onChangeCriteria?.(subdimensionId, criterion.get('id'));
    },
    300,
    { leading: false, trailing: true },
  );

  const Componet = (
    <Col
      data-test-id={`criterion-${criterion?.get('id')}`}
      className={cx(
        `criterion-${index + 1}`,
        Styles.Criterion,
        'mx-1 p-3 bg-white02 rounded text-center lh-1 d-flex align-items-center border',
        {
          [Styles.Collapsed]: isDimesionNotesOpen,
          [Styles.Selected]: isSelected,
          [Styles.SelectedByTeam]: isSelectedByTeam,
          [heatmapClass(heatRange(heat))]: heat !== undefined && heat !== null,
        },
      )}
      role={onChangeCriteria ? 'button' : undefined}
      onClick={onChangeCriteria ? handleClick : undefined}
    >
      {(!isDimesionNotesOpen || isSelected) && criterion?.get('description')}
    </Col>
  );

  return isHeatmap ? (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          {hits} click{hits !== 1 ? 's' : ''}
        </Tooltip>
      }
    >
      {Componet}
    </OverlayTrigger>
  ) : (
    Componet
  );
};

export default CriterionName;
