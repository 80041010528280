import { Col, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import { Intro as IntroType } from 'data/features/introsTypes';
import { relativeTime } from 'utils';

import IntroDelete from './IntroDelete';
import IntroEdit from './IntroEdit';
import IntroEmail from './IntroEmail';
import Styles from './Intros.module.scss';

type IntroProps = {
  intro: IntroType;
  isOrganizerScreen?: boolean;
  isTeamScreen?: boolean;
};

const Intro = ({ intro, isOrganizerScreen, isTeamScreen }: IntroProps) => {
  return (
    <div id={`intro-${intro.id}`} className={cx('px-3 py-2 rounded mb-3', Styles.Intro)}>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <small className="text-xs text-blue03">
          <strong>INTRO</strong>
        </small>

        <span className="fa-stack">
          <i className="fa-solid fa-circle fa-stack-2x text-blue03"></i>
          <i className="fa-solid fa-handshake fa-stack-1x fa-inverse"></i>
        </span>
      </div>
      <Row className="align-items-center">
        <Col>
          <small className="text-muted">
            {intro.judge?.name}
            {intro.isYou && <small className="ms-1 d-print-none">(this is you)</small>}:
          </small>
        </Col>
        {intro.email && (
          <Col xs="auto">
            <IntroEmail intro={intro} isTeamScreen={isTeamScreen} />
          </Col>
        )}
        <Col className="text-end">
          <small className="text-muted text-xs">
            {relativeTime(intro.createdAt)}
            {isOrganizerScreen && intro.edited && ' (edited)'}
          </small>
        </Col>
      </Row>
      <p className="fw-bold text-pre-wrap mt-2">
        {!intro.content && (
          <small>
            <i className="text-muted">Introduction details viewable to Selection Committee</i>
          </small>
        )}
        {intro.content && <small>{intro.content}</small>}
      </p>
      <div className="d-flex justify-content-end">
        {intro.canEdit && <IntroEdit intro={intro} />}
        {intro.canDelete && <IntroDelete intro={intro} />}
      </div>
    </div>
  );
};

export default Intro;
