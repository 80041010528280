import { Col } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import Styles from './Heat.module.scss';
import { Map } from 'immutable';

type GradeNameProps = {
  grade?: Map<string, any>;
  isDimesionNotesOpen: boolean;
};

const GradeName = ({ grade, isDimesionNotesOpen }: GradeNameProps) => {
  return (
    <Col className={cx(Styles.Grade, 'pb-3 pt-0 text-center fw-bold text-gray02')}>
      {!isDimesionNotesOpen && grade?.get('name')}
    </Col>
  );
};

export default GradeName;
