/*
  Copyright (2020) Valid Evaluation, Inc.
  Portions of this code were adapted from code originally developed and copyrighted by Data Markets Inc. between 2014-2020; used with permission.
*/
import { createConsumer } from '@rails/actioncable';
import cx from 'classnames';
import { getEnvVar } from 'config';
import {
  add,
  load,
  loadMore,
  markAllAsSeen,
  remove,
  seen,
  toggleSeen,
} from 'data/actions/userNotifications';
import {
  getUserNotifications,
  getUserNotificationsCount,
  getUserNotificationsOffset,
  getUserUnseenNotificationsCount,
} from 'data/reducers';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { Component } from 'react';
import { Button, OverlayTrigger, Popover } from '@valid-eval/shared-react-components';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { openReportURL } from './helpers';
import Styles from './NavbarListNotification.module.scss';
import NotificationItem from './NotificationItem';
import NotificationPlaceholder from './NotificationPlaceholder';
import TabUtils from 'utils/tab_utils';
import { error } from 'data/actions/notifications';
import { withTranslation } from 'react-i18next';
import { getText } from 'utils/notifications/automatch';

class NavbarListNotification extends Component {
  state = {
    sortedNotifications: [],
    isOpen: false,
  };

  componentDidMount() {
    const { user, load, add, remove, seen, error } = this.props;
    user && load(user.get('id'));

    this.cable = createConsumer(getEnvVar('REACT_APP_WS_URL', `ws://localhost:3000/api/v1/cable`));
    this.channel = this.cable.subscriptions.create(
      {
        channel: 'NotificationsChannel',
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: ({
          operation,
          notification,
          total_count: totalCount,
          unseen_count: unseenCount,
        }) => {
          switch (operation) {
            case 'seen':
              seen(user.get('id'), notification, totalCount, unseenCount);
              break;
            case 'add':
              // Handle report notification done
              const noti = notification.notification;

              if (
                noti?.action === 'report_generated' &&
                noti?.data?.report_status === 'COMPLETED' &&
                noti?.data?.report_type !== 'team_files_report'
              ) {
                TabUtils.callOnce(
                  `openReportUrlLock-${notification.notification.id}`,
                  () => {
                    openReportURL(
                      notification.notification.url,
                      notification.notification.data.report_type === 'pdf_results' ||
                        notification.notification.data.report_type === 'artifact_pdf_team_report',
                      this._showError,
                    );
                  },
                  5e3,
                );
              }

              // Handle new report notification
              if (
                noti?.action === 'report_generated' ||
                ((noti?.action === 'panel_match' || noti?.action === 'automatch') &&
                  (noti?.data?.success || noti?.data?.is_running)) ||
                noti?.action === 'team_survey'
              ) {
                this.setState(() => ({ isOpen: true }));
              }

              if (
                (noti?.action === 'automatch' || noti?.action === 'panel_match') &&
                !noti?.data?.success &&
                !noti?.data?.is_running
              ) {
                this.setState(() => ({ isOpen: false }));
                error(getText(noti));
              }

              add(user.get('id'), notification, totalCount, unseenCount);
              break;
            case 'remove':
              remove(user.get('id'), notification, totalCount, unseenCount);
              break;
            default:
              break;
          }
        },
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.notifications?.toJS?.(), prevProps.notifications?.toJS?.())) {
      this._sortNotifications();
    }
  }

  _showError = () => {
    this.props.error(this.props.t('blocked_popup_error', { url: getEnvVar('REACT_APP_HOST') }));
  };

  _sortNotifications = () => {
    const notifications = this.props.notifications.map((n) => n.toObject()).toArray();
    this.setState({
      sortedNotifications: orderBy(notifications, ['seen', 'id'], ['asc', 'desc']),
    });
  };

  handleLoadNotifications = () => {
    const { user, loadMore, notificationsOffset } = this.props;
    loadMore(user.get('id'), notificationsOffset);
  };

  handleUnreadNotificationAction = (notification) => {
    this.props.toggleSeen(notification.id);

    let nextNotifications = [...this.state.sortedNotifications];
    const index = this.state.sortedNotifications.findIndex((n) => n.id === notification.id);
    nextNotifications[index] = {
      ...nextNotifications[index],
      seen: !notification.seen,
    };

    this.setState({
      sortedNotifications: nextNotifications,
    });
  };

  handleTriggerEnter = () => {
    this._sortNotifications();
  };

  handleMarkAllAsSeenClick = () => {
    const { user, markAllAsSeen } = this.props;
    markAllAsSeen(user.get('id'));
    this.setState({
      sortedNotifications: this.state.sortedNotifications.map((n) => ({ ...n, seen: true })),
    });
  };

  onToggle = (isOpen) => this.setState({ isOpen });

  _popover = () => (
    <Popover id="notifications-popover" className="d-print-none">
      <ul className="notification stack-list">
        {this.props.notificationsCount ? (
          <div className="stackList">
            <InfiniteScroll
              pageStart={Number(this.props.notificationsOffset) || 0}
              hasMore={this.props.notificationsCount > this.props.notifications.count()}
              loadMore={this.handleLoadNotifications}
              loader={
                <div style={{ padding: 12 }} key={0}>
                  Loading more...
                </div>
              }
              useWindow={false}
            >
              {this.state.sortedNotifications.map((notification, index) => (
                <NotificationItem
                  onCloseList={() => this.onToggle(false)}
                  key={notification.id}
                  notification={notification}
                  onHandleUnreadNotificationAction={this.handleUnreadNotificationAction}
                />
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <NotificationPlaceholder />
        )}
      </ul>
      {this.props.unseenNotificationsCount > 0 && (
        <Button
          className={Styles.MarkAsReadButton}
          variant="link"
          id="notifications-mark-all-as-read"
          onClick={this.handleMarkAllAsSeenClick}
        >
          Mark all as read
        </Button>
      )}
    </Popover>
  );

  renderArrow = () => {
    const { isOpen } = this.state;
    if (isOpen) {
      return <i className={cx(Styles.NotificationArrowUp, 'fa-solid fa-angle-up')} />;
    }
    return <i className={cx(Styles.NotificationArrowDown, 'fa-solid fa-angle-down')} />;
  };

  renderTitle = () => {
    const { isOpen } = this.state;
    if (isOpen) {
      return <span className={Styles.NotificationTitleUp}>Notifications</span>;
    }
    return <span className={Styles.NotificationTitleDown}>Notifications</span>;
  };

  render() {
    return (
      <OverlayTrigger
        onClick={() => this.onToggle(true)}
        onEnter={this.handleTriggerEnter}
        onToggle={this.onToggle}
        show={this.state.isOpen}
        trigger="click"
        overlay={this._popover()}
        placement="bottom"
        rootClose
      >
        <div id="notifications-popover" role="button" onClick={this.handleTriggerClick}>
          {this.renderTitle()}
          {this.props.unseenNotificationsCount > 0 ? (
            <span className={cx(Styles.NotificationBadge, Styles.Badge)} />
          ) : (
            this.renderArrow()
          )}
        </div>
      </OverlayTrigger>
    );
  }
}

export default connect(
  (state) => {
    const notifications = getUserNotifications(state);
    const notificationsCount = getUserNotificationsCount(state);
    const unseenNotificationsCount = getUserUnseenNotificationsCount(state);
    const notificationsOffset = getUserNotificationsOffset(state);
    return { notifications, notificationsCount, unseenNotificationsCount, notificationsOffset };
  },
  { load, remove, seen, loadMore, toggleSeen, add, markAllAsSeen, error },
)(withTranslation()(NavbarListNotification));
