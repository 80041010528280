import { Button, Modal } from '@valid-eval/shared-react-components';
import { useTranslation } from 'react-i18next';

const CommunicationsSendConfirmation = ({
  show,
  onHide,
  onConfirm,
  sendTo = 'judges',
  isScheduled,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('notifications.modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {isScheduled
            ? `${t('notifications.confirm_schedule')} ${sendTo}`
            : `${t('notifications.confirm_send')} ${sendTo}`}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'link'} onClick={onHide} id="send-communication-cancel-button">
          {t('notifications.cancel_button')}
        </Button>
        <Button variant={'success'} onClick={onConfirm} id="send-communication-confirm-button">
          {isScheduled ? t('notifications.schedule_button') : t('notifications.send_button')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommunicationsSendConfirmation;
