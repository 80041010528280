import { connect } from 'react-redux';

import Loading from 'components/Loading';
import RubricReadOnlyComponent from 'components/RubricDimension/RubricReadOnly';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { getIsLoadingRubric, getRubric } from 'data/reducers';

const RubricReadOnly = ({ loading, rubric }) => {
  if (loading) return <Loading />;

  return (
    <div className="py-4">
      <h1>{rubric.get('name')}</h1>
      <RubricReadOnlyComponent rubric={rubric} />
    </div>
  );
};

export default connect(
  (state, ownProps) => {
    const { selectedPhaseId } = ownProps;

    const isLoadingRubric = getIsLoadingRubric(state);
    const rubric = selectedPhaseId && getRubric(state, selectedPhaseId);

    return {
      loading: !rubric || isLoadingRubric,
      rubric,
    };
  },
  {
    notifyError,
    notifySuccess,
  },
)(RubricReadOnly);
