import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Col, Button, Form, Modal, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { Email, Input, RichTextEditor } from 'components/FormFields';
import { email, required, requiredRichText } from 'components/FormFields/validations';
import { getEnvVar } from 'config';
import { isRunningOnIE } from 'utils';
import { allNotifications } from 'utils/notifications';
import Styles from './CommunicationsTemplate.module.scss';
import CommunicationsTemplateFormRichTextFooter from './CommunicationsTemplateFormRichTextFooter';
import ScheduleInfo from './ScheduleInfo';
import SplitButtonDropdown from './DropdownSplitButton';

const controls = [
  'bold',
  'strikethrough',
  'underline',
  'italic',
  'ul',
  'ol',
  ...(isRunningOnIE() ? [] : ['link']),
  '|',
  'undo',
  'redo',
];

const TemplateSelect = ({ templates, onTemplateChange, input, disabled }) => {
  return (
    <>
      <label className="form-label">
        <b>Type of Message</b>
      </label>
      <select
        {...input}
        disabled={disabled}
        className={cx(Styles.customSelect, Styles.arrowStyle, 'form-control')}
        onChange={(e) => onTemplateChange(templates.find((t) => t.slug === e.target.value))}
      >
        {templates.map((t) => (
          <option key={t.slug} value={t.slug}>
            {allNotifications[t.slug]?.displayName}
          </option>
        ))}
      </select>
    </>
  );
};

TemplateSelect.propTypes = {
  templates: PropTypes.array.isRequired,
  onTemplateChange: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

const CommunicationsTemplateForm = ({
  handleSubmit,
  onHide,
  onSend,
  onTemplateChange,
  show,
  template,
  templates,
  disableTemplateSelection,
  isSendButtonDisabled,
  disableScheduleButton,
}) => {
  const formValues = useSelector(getFormValues('CommunicationsTemplateForm'));
  const [changed, setChanged] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [scheduleData, setScheduleData] = useState(null);
  const [isScheduleValid, setIsScheduleValid] = useState(false);

  const handleChange = useCallback(() => setChanged(true), []);
  const handleTogglePreview = useCallback(() => setShowPreview((prev) => !prev), []);

  useEffect(() => {
    if (!show) {
      setChanged(false);
      setShowSchedule(false);
      setScheduleData(null);
      setIsScheduleValid(false);
    }
  }, [show]);

  const handleSubmitAction = useCallback(
    async (isScheduled = false) => {
      let templateToSend = template;
      if (changed) {
        templateToSend = await handleSubmit();
      } else {
        onHide();
      }
      if (templateToSend.id) {
        if (isScheduled && scheduleData) {
          onSend?.(templateToSend, scheduleData);
        } else {
          onSend?.(templateToSend);
        }
      }
    },
    [changed, handleSubmit, onHide, onSend, template, scheduleData],
  );

  const handleScheduleChange = useCallback((isSchedule) => {
    setShowSchedule(isSchedule);
  }, []);

  const handleScheduleDataChange = useCallback((isoString, isValid) => {
    setScheduleData(isoString);
    setIsScheduleValid(isValid);
  }, []);

  const preview = useMemo(() => {
    const content = formValues?.get('content') || '';
    const { template_variables: variables = {}, team_template_variables: teamVariables = {} } =
      template || {};

    let processedContent = content;
    Object.keys(variables).forEach((key) => {
      processedContent = processedContent.replaceAll(
        key,
        `<kbd>${variables[key].preview_value}</kbd>`,
      );
    });
    Object.keys(teamVariables).forEach((key) => {
      processedContent = processedContent.replaceAll(
        key,
        `<kbd>${teamVariables[key].preview_value}</kbd>`,
      );
    });
    return processedContent;
  }, [formValues, template]);

  return (
    <Modal size="lg" show={show} onHide={onHide} enforceFocus={false}>
      <Form onSubmit={handleSubmit} id={'communication-template-form'}>
        <Modal.Header closeButton>
          <Modal.Title>{allNotifications[template?.slug]?.displayName} Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body className={cx(Styles.body)}>
          <Row>
            <Col>
              <Input
                title="Reply-to Name"
                name="reply_to_name"
                className="mt-3 mb-3"
                placeholder={getEnvVar('REACT_APP_NOTIFICATION_SENDER_NAME', 'Valid Eval')}
                onChange={handleChange}
              />
            </Col>
            <Col>
              <Email
                title="Reply-to Email"
                name="reply_to_email"
                className="mt-3 mb-3"
                validate={[email]}
                placeholder={getEnvVar(
                  'REACT_APP_NOTIFICATION_SENDER_EMAIL',
                  'noreply@valideval.com',
                )}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Subject"
                name="subject"
                className="mt-0 mb-3"
                validate={[required]}
                onChange={handleChange}
              />
            </Col>
            <Col>
              <Field
                name="slug"
                className="mb-3"
                component={TemplateSelect}
                templates={templates}
                onTemplateChange={onTemplateChange}
                disabled={disableTemplateSelection}
              />
            </Col>
          </Row>
          {show && (
            <RichTextEditor
              key={template.id}
              name={'content'}
              validate={[requiredRichText]}
              onChange={handleChange}
              config={{
                buttons: controls,
                buttonsMD: controls,
                buttonsSM: controls,
                buttonsXS: controls,
                link: {
                  noFollowCheckbox: false,
                  openInNewTabCheckbox: false,
                },
                showCharsCounter: false,
                showWordsCounter: false,
                showXPathInStatusbar: false,
              }}
              format={(value) =>
                value?.replace(/(\{\{[\w-]+\}\})/gi, '<jodit-media>$1</jodit-media>')
              }
              normalize={(value) => value?.replace(/(<\/?jodit-media>)/gi, '')}
            >
              {CommunicationsTemplateFormRichTextFooter({ template })}
            </RichTextEditor>
          )}
          <Button variant="link" onClick={handleTogglePreview} className="mt-2 pe-2 ps-2">
            <i
              className={cx('fa-solid', {
                'fa-eye': !showPreview,
                'fa-eye-slash': showPreview,
              })}
            />
            {showPreview ? 'Hide' : 'Show'} preview
          </Button>
          {showPreview && (
            <div className={cx('p-4 mt-2 border rounded shadow-sm bg-light')}>
              <img
                src="https://app.valideval.com/ve_email_logo.png"
                alt="Valid Eval logo"
                style={{ maxWidth: 249 }}
              />
              <hr />
              <div dangerouslySetInnerHTML={{ __html: preview }} />
              {Boolean(template?.buttons) && template.buttons.length > 0 && (
                <div className="d-flex mt-2">
                  {template?.buttons?.map((b, index) => (
                    <Button variant={b.variant} className="me-2" key={`btn-${index}`}>
                      {b.text}
                    </Button>
                  ))}
                </div>
              )}
              {Boolean(template?.buttons) && template.buttons.length > 0 && (
                <>
                  <p className="mt-3">
                    Please either click the button above OR copy and paste the link below into your
                    browser:
                  </p>
                  <tt>{`https://${getEnvVar('REACT_APP_HOST')}/...`}</tt>
                </>
              )}
            </div>
          )}
          {showSchedule && (
            <div className="mt-2 mb-2">
              <ScheduleInfo onChange={handleScheduleDataChange} />
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            id={'communication-template-form-submit'}
            className="position-absolute"
            style={{ left: 10 }}
            type="submit"
            disabled={!changed}
          >
            Save & Close
          </Button>
          <Button
            variant="link"
            id={'communication-template-form-cancel'}
            onClick={() => onHide(true)}
          >
            {template?.slug === 'team_revision_application' ? 'Back' : 'Cancel'}
          </Button>
          <SplitButtonDropdown
            primaryText={changed ? 'Save & Send' : 'Send'}
            onPrimaryAction={() => handleSubmitAction(false)}
            onSecondaryAction={() => handleSubmitAction(true)}
            onScheduleChange={handleScheduleChange}
            disabled={isSendButtonDisabled}
            disableScheduleButton={disableScheduleButton}
            isScheduleComplete={isScheduleValid}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CommunicationsTemplateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onTemplateChange: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  template: PropTypes.object,
  templates: PropTypes.array.isRequired,
  disableTemplateSelection: PropTypes.bool,
  isSendButtonDisabled: PropTypes.bool,
};

export default reduxForm({ form: 'CommunicationsTemplateForm', enableReinitialize: true })(
  CommunicationsTemplateForm,
);
