import { useDispatch } from 'react-redux';
import { isRejectedWithValue } from '@reduxjs/toolkit';

import { getEnvVar } from 'config';
import { error } from 'data/actions/notifications';
import { useTranslation } from 'react-i18next';

export const useShowErrorOnRejectedWithValue = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (result: any) => {
    if (isRejectedWithValue(result)) {
      const payload: any = result.payload;

      const message =
        payload?.response?.error ||
        t('frontend.common.generic_error', getEnvVar('REACT_APP_SUPPORT_EMAIL'));
      dispatch(error(message));
      return;
    }
    return result;
  };
};
