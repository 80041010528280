import { Note as NoteType } from 'data/features/notesTypes';
import Note from './components/Note';

type NotesListProps = {
  compact?: boolean;
  emptyLabel?: string;
  isOrganizerScreen?: boolean;
  notes: NoteType[];
  phaseId: string;
  showDimensionName?: boolean;
  teamId: string;
};

const NotesList = ({
  compact,
  emptyLabel = 'None.',
  isOrganizerScreen,
  notes,
  phaseId,
  showDimensionName,
  teamId,
}: NotesListProps) => {
  const firstInternalNote = notes.findIndex((note) => note.type === 'internal');

  if (!notes?.length) return <small>{emptyLabel}</small>;

  return notes.map((note, index) => (
    <Note
      key={note.id}
      note={note}
      isFirstInternal={index === firstInternalNote}
      isOrganizerScreen={isOrganizerScreen}
      phaseId={phaseId}
      teamId={teamId}
      compact={compact}
      showDimensionName={showDimensionName}
    />
  ));
};

export default NotesList;
