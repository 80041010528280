import { Button, Table } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { Component, useState } from 'react';

import { isNavigator } from 'utils';
import Styles from './SuggestionsTable.module.scss';

const TableRow = ({ role, onAssign }) => {
  const [showFullCategories, setShowFullCategories] = useState(false);

  const handleAssign = () => {
    onAssign(role.assign_to_id);
  };

  return (
    <tr data-test-id={`suggestions-table-row-${role.id}`}>
      {!isNavigator() && (
        <td>
          <Button className={cx(Styles.button, 'py-1')} variant="default" onClick={handleAssign}>
            <i className="fa-regular fa-circle-plus fa-lg m-0"></i>
          </Button>
        </td>
      )}
      <td className={`text-center`}>{role.match_quality}</td>
      <td>{role.name}</td>
      <td>
        <div className={Styles.categoryContainer}>
          <div
            className={showFullCategories ? Styles.fullText : Styles.multilineEllipsis}
            dangerouslySetInnerHTML={{
              __html: role.categories.join('  <span class="text-muted">|</span>  '),
            }}
          ></div>
          <i
            className={`fa-solid fa-sm ${showFullCategories ? 'fa-chevron-up' : 'fa-chevron-down'} ${Styles.chevron}`}
            onClick={() => setShowFullCategories(!showFullCategories)}
          ></i>
        </div>
      </td>
      <td className={`text-center`}>{role.matches_count}</td>
    </tr>
  );
};

class SuggestionsTable extends Component {
  render() {
    const { list, roleType = 'Team', onAssign, thWidths } = this.props;
    return (
      <>
        <Table className={Styles.table}>
          <thead>
            <tr>
              {!isNavigator() && <th width={thWidths[0]}>Match</th>}
              <th width={thWidths[1]}>Match Quality</th>
              <th className="text-start" width={thWidths[2]}>
                {roleType}
              </th>
              <th className="text-start" width={thWidths[3]}>
                Categories
              </th>
              <th width={thWidths[4]}>{roleType === 'Team' ? 'Judge Count' : 'Team Count'}</th>
            </tr>
          </thead>
          <tbody>
            {list.map((role) => (
              <TableRow role={role} key={role.id} onAssign={onAssign} />
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

SuggestionsTable.displayName = 'SuggestionsTable';
export default SuggestionsTable;
