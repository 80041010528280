import { Button } from '@valid-eval/shared-react-components';
import { connect, ConnectedProps } from 'react-redux';

import GenericModal from 'components/GenericModal';
import { deleteNote } from 'data/features/notes';
import { Note as NoteType } from 'data/features/notesTypes';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import { useShowErrorOnRejectedWithValue } from 'utils/hooks/useShowErrorOnRejectedWithValue';
import { success as showSuccess } from 'data/actions/notifications';

type NoteDeleteProps = {
  note: NoteType;
  disabled?: boolean;
};

const mapDispatchToProps = { deleteNote, showSuccess };

const connector = connect(null, mapDispatchToProps);

const NoteDelete = ({
  note,
  disabled,
  deleteNote,
  showSuccess,
}: NoteDeleteProps & ConnectedProps<typeof connector>) => {
  const [showConfirmation, openConfirmation, closeConfirmation] = useBooleanFlag();
  const showErrorOnRejectedWithValue = useShowErrorOnRejectedWithValue();

  const handleDelete = async () => {
    closeConfirmation();
    const result = showErrorOnRejectedWithValue(
      await deleteNote({
        noteId: note.id,
        phaseId: note.phaseId,
        teamId: note.teamId,
      }),
    );
    if (result) showSuccess('The comment has been deleted');
    return result;
  };

  return (
    <>
      {note.canDelete && (
        <>
          <GenericModal
            body="Please confirm that you want to delete this comment?"
            name="delete-note"
            onCancel={closeConfirmation}
            onConfirm={handleDelete}
            show={showConfirmation}
            title="Delete Comment"
            size="md"
          />
          <Button
            name="delete"
            variant="link"
            className="p-0"
            onClick={openConfirmation}
            disabled={disabled}
          >
            <i className="m-0 fa-regular fa-trash-alt" />
          </Button>
        </>
      )}
    </>
  );
};

export default connector(NoteDelete);
