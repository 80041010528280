import { CellContext } from '@tanstack/react-table';
import { Button, Dropdown, OverlayTrigger, Tooltip } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import { TeamMember } from './types';
import Styles from 'components/Tables/CommonTableStyles.module.scss';

export const NameAndEmail = ({ row }: CellContext<TeamMember, any>) => {
  const teamMember = row.original;

  return (
    <td style={{ maxWidth: 210 }}>
      {`${teamMember.first_name || ''} ${teamMember.last_name || ''}`}
      <br />
      <small className="text-muted text-truncate">{teamMember.email}</small>
    </td>
  );
};

export const Invitation = ({ row, getValue }: CellContext<TeamMember, any>) => {
  const teamMember = row.original;
  let label = <span className="label label-default">PENDING</span>;
  if (getValue()) label = <span className="label label-success">ACCEPTED</span>;
  else if (teamMember.declined) label = <span className="label label-danger">DECLINED</span>;
  return <td className={cx('text-center', Styles.SmallerText)}>{label}</td>;
};

export const Role = ({ getValue, row, table }: CellContext<TeamMember, any>) => {
  const teamMember = row.original;
  const { onUpdateMemberRole, currentUserId, isTeamMemberEditor } = table.options.meta as any;
  const canEditRole = teamMember.user_id !== currentUserId && isTeamMemberEditor;
  const currentRole = getValue()?.toUpperCase();

  const roles = ['PRIMARY', 'EDITOR', 'VIEWER'].filter((role) => role !== currentRole);

  return (
    <td className="text-center">
      <Dropdown>
        <Dropdown.Toggle
          className={cx('p-0 pt-2 pb-2 ps-2', Styles.SmallerText, {
            'text-success': currentRole === 'PRIMARY',
          })}
          variant="link"
          disabled={!canEditRole || currentRole === 'PRIMARY'}
        >
          {currentRole}
          <i className="fa-solid fa-angle-down ms-2" />
        </Dropdown.Toggle>
        {canEditRole && (
          <Dropdown.Menu>
            {roles.map((role) => (
              <Dropdown.Item
                key={`role-${role.toLowerCase()}`}
                onClick={() =>
                  onUpdateMemberRole(teamMember.id, teamMember.user_id, role.toLowerCase())
                }
              >
                {role}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </td>
  );
};

const copyURLTooltip = (
  <Tooltip>
    <strong>Copy Sign Up URL</strong>
  </Tooltip>
);

const deleteTooltip = (
  <Tooltip>
    <strong>Delete invitation</strong>
  </Tooltip>
);

const resendTooltip = (
  <Tooltip>
    <strong>Resend invitation</strong>
  </Tooltip>
);

export const Actions = ({ getValue, row, table }: CellContext<TeamMember, any>) => {
  const teamMember = row.original;
  const { onResendInvitation, onDeleteInvitation, onCopyInviteURLToClipboard, currentUserId } =
    table.options.meta as any;
  const isCurrentUser = teamMember.user_id === currentUserId;

  return (
    <td>
      <div className="d-flex align-items-center justify-content-center">
        <OverlayTrigger placement="top" overlay={resendTooltip}>
          <Button
            onClick={() => onResendInvitation(getValue())}
            id={`resend-invitation-${getValue()}`}
            className="fa-solid fa-paper-plane btn-sm favFont"
            variant="success"
            disabled={teamMember.accepted}
          />
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={copyURLTooltip}>
          <Button
            onClick={() => onCopyInviteURLToClipboard(teamMember)}
            id={`copy-invitation-url-${getValue()}`}
            className="fa-solid fa-link ms-2 btn-sm favFont"
            variant="success"
            disabled={teamMember.accepted}
          />
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={deleteTooltip}>
          <Button
            onClick={() => onDeleteInvitation(getValue(), teamMember.user_id)}
            id={`delete-invitation-${getValue()}`}
            className="fa-regular fa-trash-can ms-2 btn-sm favFont"
            variant="danger"
            disabled={isCurrentUser || teamMember.role === 'primary'}
          />
        </OverlayTrigger>
      </div>
    </td>
  );
};
