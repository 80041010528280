import { Col, ListGroup, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Styles from './Styles.module.scss';
import AuthyAuth from 'images/icons/authy.png';
import GoogleAuth from 'images/icons/google.png';
import MicrosoftAuth from 'images/icons/ms.png';
import { StepProps } from './types';

export const AppsList = ({ currentStep, chooseStep }: StepProps) => {
  const { t } = useTranslation();

  if (currentStep !== 3) {
    return null;
  }

  const authApps = [
    { name: 'AUTHY', icon: AuthyAuth, step: 4 },
    { name: 'GOOGLE AUTHENTICATOR', icon: GoogleAuth, step: 5 },
    { name: 'MICROSOFT AUTHENTICATOR', icon: MicrosoftAuth, step: 6 },
  ];

  const handleAuthAppSetup = (step: number) => {
    chooseStep?.(step);
  };

  return (
    <>
      <p>{t('mfa_instructions.select_auth_app')}</p>
      <ListGroup className={Styles.AuthAppsList}>
        {authApps.map(({ name, icon, step }) => (
          <ListGroup.Item key={name} action onClick={() => handleAuthAppSetup(step)}>
            <Row className={cx('text-center')}>
              <Col className="d-flex flex-row">
                <div className="p-2">
                  <img className={Styles.icon} src={icon} alt={name} width="40" />
                </div>
                <div className="p-2 align-self-center">
                  <p className={Styles.justifyText}>
                    <strong>{name}</strong>
                  </p>
                  <p className={Styles.justifyText}>{t('mfa_instructions.available_on_mobile')}</p>
                  <i className={cx(Styles.RightArrow, 'fa-solid fa-angle-right ms-3')} />
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <br />
    </>
  );
};

export default AppsList;
