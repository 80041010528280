import { useLayoutEffect, useState } from 'react';

/**
 * A custom React hook that observes size changes of a DOM element.
 *
 * @param element - A React ref object pointing to the DOM element to observe.
 * @param callback - A function to call when the size of the observed element changes.
 *                   It receives an object with the new width and height.
 */
const useResizeObserver = (element: HTMLElement | null) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    // Early return if the element is not available
    if (!element) return () => {};

    // Create a new ResizeObserver
    const observer = new ResizeObserver((entries) => {
      // Call the callback with the new dimensions
      setWidth(() => entries[0].contentRect.width);
      setHeight(() => entries[0].contentRect.height);
    });

    // Start observing the element
    observer.observe(element);

    // Cleanup function to stop observing when the component unmounts
    return () => element && observer.unobserve(element);
  }, [element]); // Re-run effect if the element ref changes

  return { width, height };
};

export default useResizeObserver;
