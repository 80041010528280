import { Button, Col, Dropdown } from '@valid-eval/shared-react-components';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';

import GenericModal from 'components/GenericModal';
import Loading from 'components/Loading';
import { load as loadContent } from 'data/actions/contents';
import {
  load as loadInvitationTemplate,
  save as saveInvitationTemplate,
} from 'data/actions/invitationTemplates';
import {
  confirm,
  deleteInvitation,
  loadAll,
  preview,
  removeAllPreview,
  removePreview,
  send,
  updateInvitation,
} from 'data/actions/judgeInvitations';
import { useDeleteNotification } from 'data/actions/userNotifications';
import {
  getInvitationTemplate,
  getJudgeInvitations,
  getJudgeInvitationsPreview,
  isLoadingJudgeInvitations,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { isNavigator } from 'utils';
import { invitationsCommunications } from 'utils/notifications';

import CancelNotificationModal from './components/CancelNotificationModal';
import CommunicationsNotifyDropdown from './components/communications/CommunicationsNotifyDropdown';
import JudgeInvitationsModal from './components/JudgeInvitationsModal';
import { JudgeInvitationsTable } from './components/JudgesInvitationsTable';
import JudgesLayout from './components/JudgesLayout';

const JudgeInvitations = ({
  send,
  loading,
  judgeInvitations,
  params,
  deleteInvitation,
  loadAll,
  loadInvitationTemplate,
}) => {
  const reloading = useRef(false);
  const deleteNotification = useDeleteNotification();
  const [isInviteModalOpened, setIsInviteModalOpened] = useState(false);
  const [noInviationsSelectedModal, setNoInviationsSelectedModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const handleNoInvitationsSelectedClose = () => setNoInviationsSelectedModal(false);
  const [selectedInvitations, setSelectedInvitations] = useState([]);
  const getSelectedIds = () => selectedInvitations.map((i) => i.id).join(',');

  const reload = () => {
    reloading.current = true;
    loadAll(params.event_id);
  };

  useEffect(() => {
    loadAll(params.event_id);
    loadInvitationTemplate(params.event_id);
    loadContent(params.event_id, 'Organizer', 'Invite Judge');
  }, []);

  const handleSendInvitation = (id) => send(id, params.event_id);
  const handleDeleteInvitation = (id) => {
    deleteInvitation(id, params.event_id);
    setSelectedInvitations([]);
  };

  const handleAfterSendCommunication = () => {
    setSelectedInvitations([]);
    setTimeout(reload, 1e3);
  };

  const handleSendMultipleInvitations = () => {
    if (selectedInvitations?.length) {
      handleSendInvitation(getSelectedIds());
      setSelectedInvitations([]);
      setTimeout(reload, 1e3);
    } else {
      setNoInviationsSelectedModal(true);
    }
  };

  const handleDeleteMultipleInvitations = () => {
    if (selectedInvitations?.length) {
      handleDeleteInvitation(getSelectedIds());
      setSelectedInvitations([]);
      setTimeout(reload, 1e3);
    } else {
      setNoInviationsSelectedModal(true);
    }
  };

  const handleScheduledClick = (notificationId) => {
    setSelectedNotificationId(notificationId);
    setShowCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
    setSelectedNotificationId(null);
  };

  const handleConfirmDelete = async (deleteForAll) => {
    await deleteNotification(selectedNotificationId, deleteForAll);
    handleCancelModalClose();
    setSelectedInvitations([]);
    reload();
  };

  const expiredCount = judgeInvitations.reduce(
    (count, row) => count + (row.has_expired ? 1 : 0),
    0,
  );

  return (
    <JudgesLayout
      toolbar={
        <div className="d-flex justify-content-end flex-grow-1 mb-3 me-3">
          {!isNavigator() && (
            <>
              <Button
                variant="success"
                onClick={() => setIsInviteModalOpened(true)}
                data-test-id="judge-invitations-add-new"
                className="me-2"
              >
                <i className="fa-solid fa-plus"></i>
                Add new
              </Button>

              <CommunicationsNotifyDropdown
                afterSend={handleAfterSendCommunication}
                label={'Actions'}
                icon={'fa-cog'}
                communications={invitationsCommunications}
                extraData={{
                  invitationsIds: selectedInvitations.map((j) => j.id),
                  rowsWithScheduledNotifications: selectedInvitations.filter(
                    (j) => j.send_at && !j.delivered_at,
                  ),
                }}
                sendTo="judges"
                shouldSend={() => {
                  const isValidSelection = Boolean(selectedInvitations.length);
                  if (!isValidSelection) setNoInviationsSelectedModal(true);
                  return isValidSelection;
                }}
              >
                <>
                  <Dropdown.Item onClick={handleSendMultipleInvitations}>
                    Resend invitations
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleDeleteMultipleInvitations}>
                    Delete invitations
                  </Dropdown.Item>
                </>
              </CommunicationsNotifyDropdown>
            </>
          )}
        </div>
      }
    >
      <>
        <JudgeInvitationsModal
          show={isInviteModalOpened}
          onClose={() => setIsInviteModalOpened(false)}
        />
        <GenericModal
          show={noInviationsSelectedModal}
          confirmButton="Accept"
          title="No judges selected"
          body="Please select some judges from the table to perform the action"
          onConfirm={handleNoInvitationsSelectedClose}
          onCancel={handleNoInvitationsSelectedClose}
        />
        <CancelNotificationModal
          show={showCancelModal}
          onCancel={handleCancelModalClose}
          onConfirmDelete={handleConfirmDelete}
        />
        {loading && !reloading.current ? (
          <Loading />
        ) : (
          <>
            <div data-test-id="invitations-table-wrapper">
              <JudgeInvitationsTable
                onSelectedInvitationsChange={setSelectedInvitations}
                selectedInvitations={selectedInvitations}
                loading={loading}
                list={judgeInvitations}
                onResend={handleSendInvitation}
                onDelete={handleDeleteInvitation}
                onScheduledClick={handleScheduledClick}
              />
            </div>
            <Col md={12}>
              {Boolean(expiredCount) && (
                <div className="alert alert-warning">
                  <i className="fa-solid fa-bell-exclamation"></i> &nbsp;&nbsp;
                  {expiredCount} invitation{expiredCount > 1 ? 's' : ''} above{' '}
                  {expiredCount > 1 ? 'have' : 'has'} expired, click on the re-send invitation
                  button to extend the deadline.
                </div>
              )}
            </Col>
          </>
        )}
      </>
    </JudgesLayout>
  );
};

export default withRouter(
  connect(
    (state, ownProps) => ({
      loading: isLoadingJudgeInvitations(state),
      judgeInvitations: getJudgeInvitations(state, ownProps.params.event_id),
      judgeInvitationsPreview: getJudgeInvitationsPreview(state),
      invitationTemplate: getInvitationTemplate(state),
    }),
    {
      loadAll,
      send,
      deleteInvitation,
      preview,
      confirm,
      reset,
      removePreview,
      removeAllPreview,
      updateInvitation,
      loadInvitationTemplate,
      saveInvitationTemplate,
    },
  )(JudgeInvitations),
);
