import { Intro } from 'data/features/introsTypes';

const filterByPhaseId = (intro: Intro, phaseId: string) =>
  phaseId ? intro.phaseId === phaseId : true;
const filterByTeamId = (intro: Intro, teamId: string) => (teamId ? intro.teamId === teamId : true);
const filterByJudgeId = (intro: Intro, judgeId: string) =>
  judgeId ? intro.judge.id === judgeId : true;

export const filterIntros = (intros: Intro[], params: Record<string, any>) => {
  return intros.filter(
    (intro) =>
      filterByPhaseId(intro, params.phaseId) &&
      filterByTeamId(intro, params.teamId) &&
      filterByJudgeId(intro, params.judgeIdFilter),
  );
};
