import { apiHeaders, apiURL } from '../services/config';

export const load = (eventId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/events/${eventId}/communications`),
});

export const create = (
  eventId,
  { phaseId, slug, content, reply_to_name, reply_to_email, subject },
) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    communication: { phase_id: phaseId, slug, content, reply_to_name, reply_to_email, subject },
  }),
  endpoint: apiURL(`/events/${eventId}/communications`),
});

export const update = (
  eventId,
  communicationId,
  { phaseId, slug, content, reply_to_name, reply_to_email, subject },
) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    communication: { phase_id: phaseId, slug, content, reply_to_name, reply_to_email, subject },
  }),
  endpoint: apiURL(`/events/${eventId}/communications/${communicationId}`),
});

export const send = (eventId, communicationId, { phaseId: phase_id, ...extraData }) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    communication: {
      phase_id,
      team_profile_ids: extraData?.teamProfileIds,
      judge_profile_ids: extraData?.judgeProfileIds,
      invitations_ids: extraData?.invitationsIds,
      drafts_ids: extraData?.draftsIds,
      send_at: extraData?.send_at,
    },
  }),
  endpoint: apiURL(`/events/${eventId}/communications/${communicationId}/send_communication`),
});
