import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';

import { filterIntros } from 'components/IntrosV2/helpers';
import { del, get, post, put } from 'data/services/config';

import { Intro, IntroPayload } from './introsTypes';

export const introsAdapter = createEntityAdapter<Intro>();

const initialState = introsAdapter.getInitialState({
  saving: false,
  loading: false,
  introOffered: false,
  viewed: false,
  defaultContent: '',
});

export const fetchIntros = createAsyncThunk(
  'intros/fetch',
  async (
    { phaseId, teamId, role }: { phaseId: string; teamId: string; role: string },
    { rejectWithValue },
  ) => {
    try {
      return await get(`/phases/${phaseId}/teams/${teamId}/intros?for=${role}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createIntro = createAsyncThunk(
  'intros/create',
  async (
    { intro, phaseId, teamId }: { intro: IntroPayload; phaseId: string; teamId: string },
    { rejectWithValue },
  ) => {
    try {
      return await post(`/phases/${phaseId}/teams/${teamId}/intros`, { intro });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateIntro = createAsyncThunk(
  'intros/update',
  async (
    { phaseId, teamId, intro }: { phaseId: string; teamId: string; intro: IntroPayload },
    { rejectWithValue },
  ) => {
    try {
      return await put(`/phases/${phaseId}/teams/${teamId}/intros/${intro.id}`, { intro });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const viewIntro = createAsyncThunk(
  'intros/view',
  async (
    { phaseId, teamId, introId }: { phaseId: string; teamId: string; introId: string },
    { rejectWithValue },
  ) => {
    try {
      return await post(`/phases/${phaseId}/teams/${teamId}/intros/${introId}/view`, {});
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteIntro = createAsyncThunk(
  'intros/delete',
  async (
    { introId, phaseId, teamId }: { introId: string; phaseId: string; teamId: string },
    { rejectWithValue },
  ) => {
    try {
      return await del(`/phases/${phaseId}/teams/${teamId}/intros/${introId}`, {});
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const introsSlice = createSlice({
  name: 'introsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIntros.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchIntros.fulfilled, (state, action) => {
      introsAdapter.setMany(state, action.payload.intros);
      state.defaultContent = action.payload.defaultContent;
      state.introOffered = action.payload.introOffered;
      state.viewed = action.payload.viewed;
    });
    builder.addCase(deleteIntro.fulfilled, (state, action) => {
      introsAdapter.removeOne(state, action.meta.arg.introId);
      state.defaultContent = action.payload.defaultContent;
      state.introOffered = action.payload.introOffered;
      state.viewed = action.payload.viewed;
    });
    builder.addCase(viewIntro.fulfilled, (state, action) => {
      introsAdapter.updateOne(state, { id: action.meta.arg.introId, changes: { viewed: true } });
    });
    builder.addMatcher(isAnyOf(createIntro.fulfilled, updateIntro.fulfilled), (state, action) => {
      introsAdapter.upsertOne(state, action.payload.intro);
      state.defaultContent = action.payload.defaultContent;
      state.introOffered = action.payload.introOffered;
      state.viewed = action.payload.viewed;
    });
    builder.addMatcher(isAnyOf(fetchIntros.rejected, fetchIntros.fulfilled), (state) => {
      state.loading = false;
    });

    builder.addMatcher(
      isAnyOf(createIntro.pending, deleteIntro.pending, updateIntro.pending, viewIntro.pending),
      (state) => {
        state.saving = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        updateIntro.fulfilled,
        updateIntro.rejected,
        createIntro.fulfilled,
        createIntro.rejected,
        deleteIntro.fulfilled,
        deleteIntro.rejected,
        viewIntro.fulfilled,
        viewIntro.rejected,
      ),
      (state) => {
        state.saving = false;
      },
    );
  },
  selectors: {
    getIsSaving: (state) => state.saving,
    getIsLoading: (state) => state.loading,
    getIntros: createSelector(
      introsAdapter.getSelectors().selectAll,
      (_, params: Record<string, any>) => params,
      (intros: Intro[], params: Record<string, any>) => filterIntros(intros, params),
    ),
    getDefaultContent: (state) => state.defaultContent,
    getIntroOffered: (state) => state.introOffered,
    getViewed: (state) => state.viewed,
  },
});

export const fromIntros = introsSlice.selectors;

export default introsSlice.reducer;
