import { Map } from 'immutable';
import { connect, ConnectedProps } from 'react-redux';
import { useEffect } from 'react';

import { fetchNotes, fromNotes } from 'data/features/notes';
import { getUserRole } from './helpers';
import NotesList from './NotesList';
import Loading from 'components/Loading';

type CompareTeamsNotesProps = {
  phaseId: string;
  teamId: string;
};

const mapStateToProps = (state: Map<string, any>, { phaseId, teamId }: CompareTeamsNotesProps) => ({
  loading: fromNotes.getIsLoading(state?.toJS()),
  notes: fromNotes.getOverallNotes(state?.toJS(), {
    phaseId,
    teamId,
  }),
});

const mapDispatchToProps = {
  fetchNotes,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const CompareTeamsNotes = ({
  phaseId,
  teamId,
  notes,
  loading,
  fetchNotes,
}: CompareTeamsNotesProps & ConnectedProps<typeof connector>) => {
  useEffect(() => {
    if (phaseId && teamId) fetchNotes?.({ phaseId, teamId, role: getUserRole(true) });
  }, [phaseId, teamId, fetchNotes]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <NotesList notes={notes} phaseId={phaseId} teamId={teamId} compact showDimensionName />
      )}
    </>
  );
};

export default connector(CompareTeamsNotes);
