import { Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import Title from '../Title';
import CriterionName from './CriterionName';
import GradeName from './GradeName';
import Styles from './Heat.module.scss';
import SubdimensionName from './SubdimensionName';

const DimensionReadOnly = (props) => {
  const { dimension, grades } = props;
  const name = dimension.get('name');
  const dimensionWeight = dimension.get('weight') || 0;
  const subdimensions = dimension.get('subdimensions');
  const weight = (dimensionWeight * 100).toFixed(2);

  return (
    <div
      data-test-id={`dimension-container-${dimension.get('id')}`}
      className={cx({
        [Styles.Dimension]: true,
      })}
    >
      <div className="p-4">
        <div>
          <div className="d-flex">
            <Title component="h2" className="mb-3">
              {name}
            </Title>
            <div className="flex-grow-1 text-end text-muted fw-bold">Weight: {weight}%</div>
          </div>

          <Row className="m-0 p-0 flex-nowrap align-items-center">
            <SubdimensionName />
            {grades.map((grade) => (
              <GradeName key={grade.get('id')} grade={grade} />
            ))}
          </Row>

          {subdimensions.map((subdimension) => (
            <Row
              key={subdimension.get('id')}
              data-test-id={`subdimension-row-${subdimension.get('id')}`}
              className="m-0 p-0 flex-nowrap mb-2"
            >
              <SubdimensionName subdimension={subdimension} />
              {subdimension.get('criteria').map((criterion) => (
                <CriterionName key={criterion.get('id')} criterion={criterion} />
              ))}
            </Row>
          ))}
        </div>
      </div>
    </div>
  );
};

DimensionReadOnly.displayName = 'DimensionReadOnly';
export default DimensionReadOnly;
