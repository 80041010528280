import { Button, Col, Form, Row } from '@valid-eval/shared-react-components';
import { Input, RichTextEditor } from 'components/FormFields';
import { emailAlias, required, requiredRichText } from 'components/FormFields/validations';
import { getFormValues, reduxForm } from 'redux-form/immutable';

import PropTypes from 'prop-types';
import Styles from './JudgeInvitationsModal.module.scss';
import cx from 'classnames';
import { getEnvVar } from 'config';
import { isRunningOnIE } from 'utils';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const CONTROLS = [
  'bold',
  'strikethrough',
  'underline',
  'italic',
  'ul',
  'ol',
  ...(isRunningOnIE() ? [] : ['link']),
  '|',
  'undo',
  'redo',
];

const fromEmail = `<${getEnvVar('REACT_APP_INVITATION_EMAIL', 'info@valideval.com')}>`;
const emailAliasValidation = emailAlias(fromEmail);
const formatAlias = (alias) => alias?.replace(` ${fromEmail}`, '');
const parseAlias = (alias) => `${alias} ${fromEmail}`;

const JudgeInvitationsTemplate = ({
  onCancel,
  handleSubmit,
  onSubmit,
  invitations,
  invitationsList,
}) => {
  const formValues = useSelector(getFormValues('addJudgeInvitationTemplateForm'));
  const [showPreview, setShowPreview] = useState(false);
  const handleTogglePreview = () => setShowPreview(!showPreview);
  const variables = formValues?.get('template_variables') || {};
  let preview = formValues?.get('invitation_template') || '';
  const content = preview?.get('content');

  preview = content.replaceAll(
    '{{cohort_name}}',
    `<kbd>${variables.get('{{cohort_name}}').get('preview_value')}</kbd>`,
  );
  preview = preview.replaceAll(
    '{{reply_to_name}}',
    `<kbd>${variables.get('{{reply_to_name}}').get('preview_value')}</kbd>`,
  );
  preview = preview.replaceAll(
    '{{reply_to_email}}',
    `<kbd>${variables.get('{{reply_to_email}}').get('preview_value')}</kbd>`,
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <Input
            id="from"
            className="mt-3 mb-3"
            name="invitation_template.from"
            title="Email From *"
            placeholder="Email From"
            validate={emailAliasValidation}
            format={formatAlias}
            parse={parseAlias}
          />
        </Col>
        <Col>
          <Input
            id="subject"
            className="mt-3 mb-3"
            name="invitation_template.subject"
            title="Subject *"
            placeholder="Subject"
            validate={required}
          />
        </Col>
      </Row>
      <RichTextEditor
        config={{
          buttons: CONTROLS,
          buttonsMD: CONTROLS,
          buttonsSM: CONTROLS,
          link: {
            noFollowCheckbox: false,
            openInNewTabCheckbox: false,
          },
          showCharsCounter: false,
          showWordsCounter: false,
          showXPathInStatusbar: false,
        }}
        id="content"
        name="invitation_template.content"
        title="Email Content*"
        validate={[requiredRichText]}
        format={(value) => value?.replace(/(\{\{\w+\}\})/gi, '<jodit-media>$1</jodit-media>')}
        normalize={(value) => value?.replace(/(<\/?jodit-media>)/gi, '')}
      />
      <Button variant="link" onClick={handleTogglePreview} className="mt-2 pe-2 ps-2">
        <i
          className={cx('fa-solid', {
            'fa-eye': !showPreview,
            'fa-eye-slash': showPreview,
          })}
        />
        {showPreview ? 'Hide' : 'Show'} preview
      </Button>
      {showPreview && (
        <div className={cx('p-4 mt-2 border rounded shadow-sm bg-light')}>
          <img
            src="https://app.valideval.com/ve_email_logo.png"
            alt="Valid Eval logo"
            style={{ maxWidth: 249 }}
          />
          <hr />
          <div dangerouslySetInnerHTML={{ __html: preview }} />
        </div>
      )}
      <Row className={cx('mt-3 me-0 ms-0 pe-0 ps-0 pt-4', Styles.ModalButtons)}>
        <Col className="text-end p-0">
          <Button variant="link" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" data-test-id="submit-invitation-template-form">
            {invitations?.length || invitationsList?.length
              ? 'Save Template and Send Invitation'
              : 'Save Template'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

JudgeInvitationsTemplate.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'addJudgeInvitationTemplateForm', enableReinitialize: true })(
  JudgeInvitationsTemplate,
);
