import {
  Row,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { filterResults } from 'components/Tables/filteringHelpers';
import { FiltersState } from 'components/Tables/types';

import Controls from './components/Controls';
import { columns } from './components/Definition';
import { filterByStatus } from './components/helpers';
import Table from './components/Table';
import { JudgeInvitation } from './components/types';

export type JudgeInvitationsTableProps = {
  event: any;
  list: JudgeInvitation[];
  onScheduledClick: (id: string) => void;
  onDelete: (id: string) => void;
  onResend: (id: string) => void;
  onSelectedInvitationsChange: (invitations: JudgeInvitation[]) => void;
  selectedInvitations: JudgeInvitation[];
};

const JudgeInvitationsTable = ({
  list,
  onDelete,
  onResend,
  onScheduledClick,
  selectedInvitations,
  onSelectedInvitationsChange,
}: JudgeInvitationsTableProps) => {
  const [globalFilter, setGlobalFilter] = useState<FiltersState>({
    textFilter: '',
    otherFilters: [],
  });
  const [rowSelection, setRowSelection] = useState({});

  const searchableFields = ['full_name', 'email'];

  // Set selection for external component
  useEffect(() => {
    const selectedRows = Object.keys(rowSelection).map((key) => (list as any)[key]);
    onSelectedInvitationsChange(selectedRows);
  }, [rowSelection]);

  // Selection cleared out outside this component
  useEffect(() => {
    if (
      selectedInvitations.length === 0 &&
      selectedInvitations.length !== Object.keys(rowSelection).length
    ) {
      setRowSelection({});
    }
  }, [selectedInvitations]);

  // Table Filtering
  const handleTextFilterChange = (textFilter: string) => {
    setGlobalFilter({ ...globalFilter, textFilter });
  };

  const handleOtherFiltersChange = (otherFilters: any[]) => {
    setGlobalFilter({ ...globalFilter, otherFilters: [...otherFilters] });
  };

  // Row selection
  const enableRowSelection = (row: Row<JudgeInvitation>) => {
    return !row.original.accepted;
  };

  const tableModel = useReactTable({
    data: list,
    columns,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection,
    meta: {
      onResend,
      onDelete,
      onScheduledClick,
    },
    state: {
      globalFilter,
      rowSelection,
    },
    globalFilterFn: filterResults(searchableFields, filterByStatus),
  });

  return (
    <>
      <Controls
        textFilter={globalFilter.textFilter}
        data={list}
        filters={globalFilter.otherFilters}
        onOtherFiltersChange={handleOtherFiltersChange}
        onTextFilterChange={handleTextFilterChange}
      />
      <Table tableModel={tableModel} />
    </>
  );
};

export default JudgeInvitationsTable;
