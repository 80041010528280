import { Button, Col, Row } from '@valid-eval/shared-react-components';
import { ConnectedProps, connect } from 'react-redux';

import GenericModal from 'components/GenericModal';
import { ApiKey, addApiKey, loadApiKeys, revokeApiKey } from 'data/actions/apiKeys';
import { error as showError } from 'data/actions/notifications';
import { Map } from 'immutable';
import { useState } from 'react';
import AddApiKeyModal from './AddApiKeyModal';

/** Redux types */
type OwnProps = {
  selectedRows: ApiKey[];
  organizationId: string;
  organizationName: string;
  clearSelection(): void;
};

const mapStateToProps = (_: any, __: OwnProps) => ({});

const mapDispatchToProps = {
  loadApiKeys,
  revokeApiKey,
  addApiKey,
  showError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type ApiKeysTableActionsProps = PropsFromRedux & OwnProps;

const ApiKeysTableActions = ({
  addApiKey,
  clearSelection,
  loadApiKeys,
  organizationId,
  organizationName,
  revokeApiKey,
  selectedRows,
  showError,
}: ApiKeysTableActionsProps) => {
  const [loading, setLoading] = useState(false);
  const [showRevokeApiKeyModal, setShowRevokeApiKeyModal] = useState(false);
  const [showAddApiKey, setShowAddApiKey] = useState(false);
  const [generatedKey, setGeneratedKey] = useState(null);

  function handleRevokeApiKeys() {
    setShowRevokeApiKeyModal(true);
  }

  function handleCancelRevokeApiKey() {
    setShowRevokeApiKeyModal(false);
  }

  async function handleConfirmRevokeApiKey() {
    try {
      setLoading(true);
      await Promise.all(selectedRows.map((row) => revokeApiKey({ id: row.id, organizationId })));
      setShowRevokeApiKeyModal(false);
      clearSelection();
    } catch {
      showError('There was an error revoking the api keys, please try again.');
    } finally {
      await loadApiKeys();
      setLoading(false);
    }
  }

  function handleAddApiKey() {
    setGeneratedKey(null);
    setShowAddApiKey(true);
  }

  async function handleCancelAddApiKey() {
    setShowAddApiKey(false);
    await loadApiKeys();
  }

  async function handleConfirmAddApiKey(values: Map<string, string>) {
    try {
      setLoading(true);
      const vals = values.toJS();
      const res = await addApiKey(vals);
      // @ts-ignore
      setGeneratedKey(res?.payload?.key);
    } catch {
      showError('There was an error creating the api key, please try again.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Row>
        <Col xs={12} className="d-flex justify-content-end my-1">
          <Button
            className="py-2 px-2 text-primary"
            variant="link"
            onClick={handleAddApiKey}
            data-test-id={`add-api-key-${organizationId}`}
          >
            Add
            <span className="fa-solid fa-plus ms-2 text-primary" />
          </Button>
          <Button
            className="py-2 px-2"
            variant="link-danger"
            disabled={!Object.keys(selectedRows).length}
            onClick={handleRevokeApiKeys}
            data-test-id={`revoke-api-key-${organizationId}`}
          >
            Revoke
            <span className="fa-solid fa-trash-can ms-2" />
          </Button>
        </Col>
      </Row>
      {/* @ts-ignore needed because generic modal it's not a TSX component */}
      <GenericModal
        size="md"
        show={showRevokeApiKeyModal}
        onCancel={handleCancelRevokeApiKey}
        onConfirm={handleConfirmRevokeApiKey}
        name={'revoke-api-key-modal'}
        title={'Revoke Api Keys'}
        body={'Are you sure you want to revoke the selected api keys?'}
        cancelButton={'Cancel'}
        confirmButton={'Confirm'}
        isCancelButtonDisabled={loading}
        isButtonDisabled={loading}
      />
      {/* @ts-ignore needed because generic modal it's not a TSX component */}
      <AddApiKeyModal
        generatedKey={generatedKey}
        onCancel={handleCancelAddApiKey}
        onFinish={handleCancelAddApiKey}
        onConfirmAddApiKey={handleConfirmAddApiKey}
        organizationId={organizationId}
        organizationName={organizationName}
        loading={loading}
        show={showAddApiKey}
      />
    </>
  );
};

export default connector(ApiKeysTableActions);
