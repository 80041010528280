import { Button } from '@valid-eval/shared-react-components';
import { useState } from 'react';
import copy from 'copy-to-clipboard';
import { connect, ConnectedProps } from 'react-redux';

import { Intro } from 'data/features/introsTypes';
import { viewIntro } from 'data/features/intros';
import { useShowErrorOnRejectedWithValue } from 'utils/hooks/useShowErrorOnRejectedWithValue';
import { success } from 'data/actions/notifications';

type IntroEmailProps = {
  intro: Intro;
  isTeamScreen?: boolean;
};

const mapDispatchToProps = {
  viewIntro,
  success,
};

const connector = connect(null, mapDispatchToProps);

const IntroEmail = ({
  intro,
  isTeamScreen,
  viewIntro,
  success,
}: IntroEmailProps & ConnectedProps<typeof connector>) => {
  const showErrorOnRejectedWithValue = useShowErrorOnRejectedWithValue();
  const [showEmail, setShowEmail] = useState(intro.viewed);

  const handleShowEmail = async () => {
    setShowEmail(true);
    copy(intro.email);
    const result = showErrorOnRejectedWithValue(
      await viewIntro({ phaseId: intro.phaseId, teamId: intro.teamId, introId: intro.id }),
    );
    if (result) success('Email copied to clipboard');
  };

  return (
    <small>
      Email:
      {isTeamScreen && !showEmail ? (
        <Button
          name="view-intro"
          className="py-2 px-3 ms-2"
          variant="primary"
          onClick={handleShowEmail}
        >
          Show and copy to clipboard
        </Button>
      ) : (
        <a className="text-blue02 ms-2" href={`mailto:${intro.email}`}>
          {intro.email}
        </a>
      )}
    </small>
  );
};

export default connector(IntroEmail);
