import { Button } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { Note } from 'data/features/notesTypes';

const NoteEdit = ({ note, onClick }: { note: Note; onClick: () => void }) => {
  if (!note.canEdit) return null;

  return (
    <Button
      name="edit"
      variant="link"
      className={cx('p-0', { 'me-3': note.canDelete })}
      onClick={onClick}
    >
      <i className="m-0 fa-regular fa-pen-to-square" />
    </Button>
  );
};

export default NoteEdit;
