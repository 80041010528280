import { useDispatch } from 'react-redux';
import { RSAA } from 'redux-api-middleware';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import * as api from 'data/services/communications';

export const actions = {
  LOAD_COMMUNICATIONS_PENDING: 'intros/LOAD_COMMUNICATIONS_PENDING',
  LOAD_COMMUNICATIONS_FULFILLED: 'intros/LOAD_COMMUNICATIONS_FULFILLED',
  LOAD_COMMUNICATIONS_REJECTED: 'intros/LOAD_COMMUNICATIONS_REJECTED',
  CREATE_COMMUNICATION_PENDING: 'intros/CREATE_COMMUNICATION_PENDING',
  CREATE_COMMUNICATION_FULFILLED: 'intros/CREATE_COMMUNICATION_FULFILLED',
  CREATE_COMMUNICATION_REJECTED: 'intros/CREATE_COMMUNICATION_REJECTED',
  UPDATE_COMMUNICATION_PENDING: 'intros/UPDATE_COMMUNICATION_PENDING',
  UPDATE_COMMUNICATION_FULFILLED: 'intros/UPDATE_COMMUNICATION_FULFILLED',
  UPDATE_COMMUNICATION_REJECTED: 'intros/UPDATE_COMMUNICATION_REJECTED',
  SEND_COMMUNICATION_PENDING: 'intros/SEND_COMMUNICATION_PENDING',
  SEND_COMMUNICATION_FULFILLED: 'intros/SEND_COMMUNICATION_FULFILLED',
  SEND_COMMUNICATION_REJECTED: 'intros/SEND_COMMUNICATION_REJECTED',
};

export const loadCommunications = (eventId) => {
  return {
    [RSAA]: {
      ...api.load(eventId),
      types: [
        actions.LOAD_COMMUNICATIONS_PENDING,
        actions.LOAD_COMMUNICATIONS_FULFILLED,
        actions.LOAD_COMMUNICATIONS_REJECTED,
      ],
    },
  };
};

export const createCommunication = (
  eventId,
  { phaseId, slug, content, subject, reply_to_name, reply_to_email },
) => {
  return {
    [RSAA]: {
      ...api.create(eventId, { phaseId, slug, content, subject, reply_to_name, reply_to_email }),
      types: [
        actions.CREATE_COMMUNICATION_PENDING,
        actions.CREATE_COMMUNICATION_FULFILLED,
        actions.CREATE_COMMUNICATION_REJECTED,
      ],
    },
  };
};

export const updateCommunication = (
  eventId,
  communicationId,
  { phaseId, slug, content, subject, reply_to_name, reply_to_email },
) => {
  return {
    [RSAA]: {
      ...api.update(eventId, communicationId, {
        phaseId,
        slug,
        content,
        subject,
        reply_to_name,
        reply_to_email,
      }),
      types: [
        actions.UPDATE_COMMUNICATION_PENDING,
        actions.UPDATE_COMMUNICATION_FULFILLED,
        actions.UPDATE_COMMUNICATION_REJECTED,
      ],
    },
  };
};

export const sendCommunication = (eventId, communicationId, { phaseId, ...extraData }) => {
  return {
    [RSAA]: {
      ...api.send(eventId, communicationId, {
        phaseId,
        ...extraData,
      }),
      types: [
        actions.SEND_COMMUNICATION_PENDING,
        actions.SEND_COMMUNICATION_FULFILLED,
        actions.SEND_COMMUNICATION_REJECTED,
      ],
    },
  };
};

export const useLoadCommunications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return async (eventId) => {
    const result = await dispatch(loadCommunications(eventId));
    result.error && dispatch(notifyError(t('notifications.error'), 'Error'));
    return result;
  };
};

export const useCreateCommunication = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return async (eventId, { phaseId, slug, content, reply_to_name, reply_to_email, subject }) => {
    const result = await dispatch(
      createCommunication(eventId, {
        phaseId,
        slug,
        content,
        reply_to_name,
        reply_to_email,
        subject,
      }),
    );
    result.error && dispatch(notifyError(t('notifications.error'), 'Error'));
    !result.error && dispatch(notifySuccess(t('notifications.success'), 'Success'));
    return result;
  };
};

export const useUpdateCommunication = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return async (
    eventId,
    communicationId,
    { phaseId, slug, content, reply_to_name, reply_to_email, subject },
  ) => {
    const result = await dispatch(
      updateCommunication(eventId, communicationId, {
        phaseId,
        slug,
        content,
        reply_to_name,
        reply_to_email,
        subject,
      }),
    );
    result.error && dispatch(notifyError(t('notifications.error'), 'Error'));
    !result.error && dispatch(notifySuccess(t('notifications.success'), 'Success'));
    return result;
  };
};

export const useSendCommunication = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return async (eventId, communicationId, { phaseId, send_at, ...extraData }) => {
    if (send_at && moment(send_at).isBefore(moment())) {
      dispatch(notifyError(t('notifications.invalid_date'), 'Error'));
      return { error: true };
    }
    const result = await dispatch(
      sendCommunication(eventId, communicationId, { phaseId, send_at, ...extraData }),
    );
    if (result.error) {
      dispatch(notifyError(t('notifications.error'), 'Error'));
    } else {
      const message = send_at ? t('notifications.scheduled_success') : t('notifications.success');
      dispatch(notifySuccess(message, 'Success'));
    }
    return result;
  };
};
