import { CellContext } from '@tanstack/react-table';
import {
  Button,
  Form,
  OverlayTrigger,
  ProgressBar,
  Tooltip,
} from '@valid-eval/shared-react-components';
import cx from 'classnames';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import LinkContainer from 'components/LinkContainer';
import i18n from 'config/i18n';
import { dateFormatted, dateInTimeZone, isNavigator, isTeamLead } from 'utils';
import { allNotifications } from 'utils/notifications';
import { organizerNav } from 'utils/urls';

import Styles from './CommonTableStyles.module.scss';
import { ToggleButton } from './ToggleButton';

type CellProps = CellContext<any, any> & { className?: string };

export const Checkbox = ({ getValue, row, table }: CellProps) => {
  const meta = table.options.meta as any;
  const tableid = meta?.tableId ? `-${meta.tableId}` : '';

  return (
    <td className={cx(Styles.CheckboxColumn, 'text-center')}>
      <div data-test-id={`checkbox-${getValue()}${tableid}`}>
        <Form.Check
          checked={row.getIsSelected()}
          id={`checkbox-${getValue()}${tableid}`}
          label=""
          onChange={() => row.toggleSelected(!row.getIsSelected())}
          disabled={!row.getCanSelect()}
        />
      </div>
    </td>
  );
};

export const Active = ({ getValue, row, table }: CellContext<any, any>) => {
  const meta = table.options.meta as any;

  return (
    <td className={cx('text-center')}>
      <ToggleButton
        record={row.original}
        active={getValue()}
        onActiveChange={meta.onActiveChange}
      />
    </td>
  );
};

export const ProfileLink = ({ getValue, row, table }: CellContext<any, string>) => {
  const meta = table.options.meta as any;
  const {
    mainEntity: { eventId, mainEntity },
  } = meta;

  return (
    <td className={cx('text-start', Styles.ProfileLink)}>
      <Link to={organizerNav.profile(eventId, mainEntity, row.original.id)}>{getValue()}</Link>
    </td>
  );
};

export const BoldText = ({ getValue, className }: CellProps) => (
  <td className={cx(Styles.SmallerText, 'text-center', className)}>
    <strong>{getValue() || ''}</strong>
  </td>
);

export const SmallText = ({ getValue, className }: CellProps) => {
  return <td className={cx(Styles.SmallerText, 'text-center', className)}>{getValue() || ''}</td>;
};

export const Text = ({ getValue, className }: CellProps) => {
  return <td className={cx('text-center', className)}>{getValue() || ''}</td>;
};

export const Array = ({ getValue, className }: CellProps) => {
  return <td className={cx('text-center', className)}>{getValue()?.join?.(', ') || ''}</td>;
};

export const NumberOrZero = ({ getValue, className }: CellProps) => {
  return <td className={cx(Styles.NumerOrZero, 'text-center', className)}>{getValue() || 0}</td>;
};

export const DateTime = ({ getValue, className }: CellProps) => {
  const date = getValue();
  return (
    <td className={cx(Styles.SmallerText, 'text-center', className)}>
      {date && dateInTimeZone(date)}
      {!date && 'N/A'}
    </td>
  );
};

export const Date = ({ getValue, className }: CellProps) => {
  const date = getValue();
  return (
    <td className={cx(Styles.SmallerText, 'text-center', className)}>
      {date && dateFormatted(date)}
      {!date && 'N/A'}
    </td>
  );
};

export const MatchedEntities = ({ getValue, table }: CellContext<any, any>) => {
  const meta = table.options.meta as any;
  const {
    assignedEntity: { eventId, assignedEntity },
  } = meta;
  const matchedEntities = getValue() as {
    profileId: string;
    name: string;
    locked: boolean;
    scored: boolean;
  }[];

  return (
    <td className={cx('text-left', Styles.MatchedEntities)}>
      <ul className="list-unstyled m-0">
        {matchedEntities.map((entity, index) => {
          const { profileId, name } = entity;
          return (
            <li key={index}>
              <div className="me-1 d-inline-block" style={{ width: 14, display: 'inline' }}>
                {entity.scored && !isNavigator() && !isTeamLead() && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        <strong>
                          Can not remove this match because the judge has begun the evaluation
                        </strong>
                      </Tooltip>
                    }
                  >
                    <i className="fa-duotone fa-lock-keyhole text-muted-light"></i>
                  </OverlayTrigger>
                )}
              </div>
              <Link to={organizerNav.profile(eventId, assignedEntity, profileId)}>{name}</Link>
            </li>
          );
        })}
      </ul>
    </td>
  );
};

export const MatchActions = ({ getValue, table }: CellContext<any, any>) => {
  const meta = table.options.meta as any;
  const {
    mainEntity: { eventId, mainEntity },
  } = meta;

  return (
    <td className={cx('text-center')}>
      <LinkContainer to={organizerNav.editAssignments(eventId, mainEntity, getValue())}>
        <Button variant="success">{isNavigator() ? 'Matches' : 'Match'}</Button>
      </LinkContainer>
    </td>
  );
};

export const Progress = ({ getValue }: CellContext<any, any>) => {
  const value = getValue();
  return (
    <td>
      <div className="d-flex align-items-center justify-content-center">
        <ProgressBar
          variant={value <= 33 ? 'danger' : value <= 67 ? 'warning' : 'success'}
          now={value}
          className={Styles.ProgressBar}
        />
        <small className={cx('fw-normal ms-2', Styles.ProgressNumber)}>{value}%</small>
      </div>
    </td>
  );
};

export const ProgressWithAmounts = ({ getValue }: CellContext<any, any>) => {
  const value = getValue();
  const progressPercentage =
    value.completed > 0 && value.total > 0 ? Math.round((value.completed / value.total) * 100) : 0;
  return (
    <td>
      <div className="d-flex align-items-center justify-content-center">
        <ProgressBar
          variant={
            progressPercentage <= 33 ? 'danger' : progressPercentage <= 67 ? 'warning' : 'success'
          }
          now={progressPercentage}
          className={Styles.ProgressBar}
        />
        <small className={cx('fw-normal ms-2', Styles.ProgressNumber)}>
          {value.completed} / {value.total}
        </small>
      </div>
    </td>
  );
};

export const AssignedEntities = ({ getValue, table, row }: CellContext<any, any>) => {
  const meta = table.options.meta as any;
  const {
    mainEntity: { eventId, mainEntity },
  } = meta;

  return (
    <td className={cx('text-center')}>
      <Link
        to={organizerNav.editAssignments(eventId, mainEntity, row.original.id)}
        className={cx(Styles.link, Styles.Matched)}
      >
        {getValue()}
      </Link>
    </td>
  );
};

const ApplicationRevisionRequestMessage = ({ row }: { row: CellContext<any, any>['row'] }) => {
  const { applicationRevisionRequest } = row.original;
  const deadline = dateInTimeZone(applicationRevisionRequest.deadline);
  const updatedAt = dateInTimeZone(applicationRevisionRequest.application_updated_at);

  let message = i18n.t('application_revision_request.notification_message_requested');
  let dateMessage = deadline
    ? i18n.t('application_revision_request.notification_message_deadline_requested', { deadline })
    : i18n.t('application_revision_request.notification_message_no_deadline_requested');

  if (updatedAt) {
    message = i18n.t('application_revision_request.notification_message_updated');
    dateMessage = i18n.t('application_revision_request.notification_message_deadline_updated', {
      application_updated_at: updatedAt,
    });
  } else if (deadline && moment().isAfter(moment(applicationRevisionRequest.deadline))) {
    message = i18n.t('application_revision_request.notification_message_expired');
  }

  return (
    <td className={cx(Styles.SmallerText, 'text-center')}>
      <small>
        <b>
          {message}
          <br />
          {dateMessage}
        </b>
      </small>
    </td>
  );
};

export const LatestMessage = ({ getValue, row, table }: CellContext<any, any>) => {
  const value = getValue();
  const original = row.original;
  const meta = table.options.meta as any;

  const notificationName = (allNotifications as any)[value?.action]?.displayName;
  const sendAt = dateInTimeZone(value?.send_at);
  const delivered = dateInTimeZone(value?.delivered_at);

  const handleScheduledClick = () => {
    meta?.onScheduledClick?.(value);
  };

  const renderScheduledMessage = () => (
    <td className={cx(Styles.SmallerText, 'text-center')}>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <strong>{notificationName}</strong>
          </Tooltip>
        }
      >
        <Button
          variant="link"
          className={cx(Styles.SmallerText, 'p-0')}
          onClick={handleScheduledClick}
          id={`cancel-${value?.batch_id}`}
        >
          <small>
            Scheduled
            <br />
            <span className="text-primary">{sendAt}</span>
          </small>
        </Button>
      </OverlayTrigger>
    </td>
  );

  if (value?.action === 'team_revision_application' && original.applicationRevisionRequest) {
    return sendAt && !delivered ? (
      renderScheduledMessage()
    ) : (
      <ApplicationRevisionRequestMessage row={row} />
    );
  }

  if (sendAt && !delivered) {
    return renderScheduledMessage();
  }

  return (
    <td className={cx(Styles.SmallerText, 'text-center')}>
      {!value ? (
        <p className="py-2 m-0">No messages</p>
      ) : (
        <small>
          <b>
            {notificationName}
            <br />
            {delivered || sendAt}
          </b>
        </small>
      )}
    </td>
  );
};

export const Boolean = ({ getValue }: CellContext<any, any>) => {
  const value = getValue();
  return (
    <td className={cx(Styles.SmallerText, 'text-center')} style={{ width: 50 }}>
      <div>{value ? 'Yes' : 'No'}</div>
    </td>
  );
};
