import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  useCreateCommunication,
  useLoadCommunications,
  useSendCommunication,
  useUpdateCommunication,
} from 'data/actions/communications';
import { getSelectedPhaseIdForCurrentEvent } from 'data/reducers';
import { useCommunicationTemplates } from 'data/reducers/communications';
import withRouter from 'routes/withRouter';

import CommunicationsSendConfirmation from './CommunicationsSendConfirmation';
import CommunicationsTemplateForm from './CommunicationsTemplateForm';

function CommunicationsNotify({
  afterSend,
  closeModalForm,
  communications,
  currentTemplate,
  eventId,
  extraData,
  phaseId,
  sendTo,
  setCurrentTemplate,
  shouldSend,
  showModalForm,
  disableTemplateSelection,
  beforeSend,
  rowsWithScheduledNotifications,
}) {
  const create = useCreateCommunication();
  const update = useUpdateCommunication();
  const send = useSendCommunication();
  const loadCommunications = useLoadCommunications();

  useEffect(() => {
    loadCommunications(eventId);
  }, []);

  const templates = useCommunicationTemplates({
    templates: Object.keys(communications),
    eventId,
    phaseId,
  });

  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [scheduleData, setScheduleData] = useState(null);
  const [disableScheduleButton, setDisableScheduleButton] = useState(false);

  useEffect(() => {
    if (extraData.rowsWithScheduledNotifications?.length > 0) {
      setDisableScheduleButton(true);
    } else {
      setDisableScheduleButton(false);
    }
  }, [extraData.rowsWithScheduledNotifications]);

  const handleSend = (template, scheduleData = null) => {
    if (!shouldSend || shouldSend(template)) {
      setCurrentTemplate(template);
      setScheduleData(scheduleData);
      setShowModalConfirm(true);
    }
  };

  const handleCancelSend = (e) => {
    e?.preventDefault();
    setShowModalConfirm(false);
    setScheduleData(null);
  };

  const handleHideEditTemplate = (canceled = false) => {
    setCurrentTemplate(null);
    closeModalForm(canceled);
  };

  const handleSubmitEditTemplate = async (values) => {
    const communicationTemplate = values?.toJS();

    const result = communicationTemplate.event_id
      ? await update(eventId, communicationTemplate.id, {
          phaseId: phaseId,
          slug: currentTemplate.slug,
          content: communicationTemplate.content,
          reply_to_name: communicationTemplate.reply_to_name,
          reply_to_email: communicationTemplate.reply_to_email,
          subject: communicationTemplate.subject,
        })
      : await create(eventId, {
          phaseId: phaseId,
          slug: currentTemplate.slug,
          content: communicationTemplate.content,
          reply_to_name: communicationTemplate.reply_to_name,
          reply_to_email: communicationTemplate.reply_to_email,
          subject: communicationTemplate.subject,
        });

    await loadCommunications(eventId);

    setCurrentTemplate(null);
    closeModalForm();
    return result?.payload?.communication;
  };

  const handleConfirmSend = async () => {
    if (beforeSend) await beforeSend();
    const sendData = { phaseId, ...extraData };
    if (scheduleData) {
      sendData.send_at = scheduleData;
    }
    await send(eventId, currentTemplate.id, sendData);
    setShowModalConfirm(false);
    setCurrentTemplate(null);
    setScheduleData(null);

    afterSend?.();
  };

  return (
    <>
      <CommunicationsSendConfirmation
        sendTo={sendTo}
        show={showModalConfirm && Boolean(currentTemplate)}
        onHide={handleCancelSend}
        onConfirm={handleConfirmSend}
        isScheduled={Boolean(scheduleData)}
        scheduleData={scheduleData}
      />
      <CommunicationsTemplateForm
        disableTemplateSelection={disableTemplateSelection}
        onSubmit={handleSubmitEditTemplate}
        initialValues={currentTemplate}
        show={showModalForm && Boolean(currentTemplate)}
        onHide={handleHideEditTemplate}
        templates={templates}
        onTemplateChange={setCurrentTemplate}
        template={currentTemplate}
        onSend={handleSend}
        isSendButtonDisabled={
          !extraData?.teamProfileIds?.length &&
          !extraData?.invitationsIds?.length &&
          !extraData?.judgeProfileIds?.length &&
          !extraData?.draftsIds?.length
        }
        disableScheduleButton={disableScheduleButton}
      />
    </>
  );
}

export default withRouter(
  connect((state, ownProps) => {
    return {
      eventId: ownProps.params.event_id,
      phaseId: getSelectedPhaseIdForCurrentEvent(state, ownProps),
    };
  })(CommunicationsNotify),
);
