import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import initializeInterceptor from 'utils/interceptors';

type FetchInterceptorProps = {
  children: React.ReactNode;
};

const FetchInterceptor = ({ children }: FetchInterceptorProps) => {
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (navigate && !initialized) {
      initializeInterceptor(navigate);
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return <>{children}</>;
};

export default FetchInterceptor;
