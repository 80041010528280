import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Styles from './TeamCompareSelector.module.scss';
import TeamSelect from './TeamSelect';

function TeamSelectorControl({
  team,
  teams,
  letter,
  onSelectTeam,
  isZScore,
  eventId,
  phaseId,
  phase,
}) {
  const [teamSelected, setTeamSelected] = useState(team);

  const handleRemoveClick = () => {
    setTeamSelected(null);
    onSelectTeam?.(letter, null);
  };
  const handleOnSelectTeam = (letter, team) => {
    setTeamSelected(team);
    onSelectTeam?.(letter, team);
  };

  useEffect(() => {
    if (team) setTeamSelected(team);
  });

  return (
    <div className={Styles.Team}>
      <TeamSelect
        isZScore={isZScore}
        teams={teams}
        team={team}
        letter={letter}
        onSelectTeam={handleOnSelectTeam}
        eventId={eventId}
        teamSelected={teamSelected}
        evalId={team ? team.eval_id : null}
        handleRemoveClick={handleRemoveClick}
      />
    </div>
  );
}

TeamSelectorControl.propTypes = {
  isZScore: PropTypes.bool,
  onSelectTeam: PropTypes.func.isRequired,
  team: PropTypes.object,
  letter: PropTypes.string,
  teams: PropTypes.array.isRequired,
};

export default TeamSelectorControl;
