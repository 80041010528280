import { Col, Row } from '@valid-eval/shared-react-components';
import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';
import cx from 'classnames';

import { sortTOCHeaders } from './Help/helpers';

import { loadHelpContent } from '../../../data/services/utils';
import classes from './HelpCMSPage.module.scss';
import RelNotes from './RelNotes';

const getElemDistance = function (elem) {
  let location = 0;
  if (elem.offsetParent) {
    do {
      location += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
  }
  return location >= 0 ? location : 0;
};

const HelpPage = ({ role, footerLinks, viewRelNotes }) => {
  const [content, setContent] = useState('');
  const [toc, setToc] = useState([]);
  const [current, setCurrent] = useState(null);
  const [menuOffset, setMenuOffset] = useState(60);

  // Load data
  useEffect(() => {
    loadHelpContent(role).then(({ toc, content }) => {
      setToc(sortTOCHeaders(toc));
      setContent(content);
      const { hash } = window.location;
      if (hash) setTimeout(() => scrollTo(hash.replace('#', '')), 200);
    });
  }, []);

  // Scroll Listener
  useEffect(() => {
    const handleScroll = throttle(
      (_) => {
        // Find the current active section index
        const sections = document.querySelectorAll(
          '#help-content h1, #help-content h2, #help-content h3',
        );
        const findCurrentIndex = (section, index) => {
          return window.scrollY >= section.offsetTop - 140;
        };
        const current = sections.length - [...sections].reverse().findIndex(findCurrentIndex) - 1;
        // Set the current to the state
        setCurrent(sections?.[current]?.id);
      },
      50,
      { leading: false, trailing: true },
    );

    window.addEventListener('scroll', handleScroll);
    // Trigger on load
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // On current changed
  useEffect(() => {
    const section = document
      .querySelectorAll(`.${classes.active}`)?.[0]
      ?.closest(`.${classes.section}`);
    setMenuOffset(60 - section?.offsetTop || 0);
  }, [current]);

  // On link click
  const scrollTo = (target) => {
    if (window.history.pushState) {
      window.history.pushState(null, null, `#${target}`);
    } else {
      window.location.hash = `#${target}`;
    }
    const targetEl = document.getElementById(target);
    if (!targetEl) return;

    const elementPosition = getElemDistance(targetEl);
    const offsetPosition = elementPosition - 80;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const selected = current ?? toc?.[0]?.target;

  const hasActive = (header) =>
    header.target === selected ||
    header.subHeader.some((subHeader) => subHeader.target === selected);

  return (
    toc &&
    content && (
      <>
        <h1 className="mb-0">Valid Eval Help</h1>
        <div className={classes.linksContainer}>{footerLinks}</div>
        <Row className="mt-3">
          {viewRelNotes ? (
            <RelNotes />
          ) : (
            <>
              <Col md={4} id="help-toc">
                <div style={{ position: 'sticky', top: menuOffset, transition: 'all 0.25s ease' }}>
                  {toc.map((i, index) => (
                    <div
                      key={index}
                      id={i.class}
                      className={cx(classes.section, { [classes.sectionActive]: hasActive(i) })}
                    >
                      <a
                        className={cx(classes.header, {
                          [classes.active]: selected === i.target,
                        })}
                        id={`toc-${i.target}`}
                        href={`#${i.target}`}
                        key={`${index}-${i.target}`}
                        onClick={(e) => {
                          e.preventDefault();
                          scrollTo(i.target);
                        }}
                      >
                        {i.text}
                        {selected === i.target && (
                          <i className={cx('fa-solid fa-arrow-right', classes.arrowMargin)} />
                        )}
                      </a>
                      {i.subHeader.map((j) => (
                        <a
                          className={cx(classes.subHeader, {
                            [classes.active]: selected === j.target,
                          })}
                          id={`toc-${i.target}-${j.target}`}
                          key={`${index}-${j.target}`}
                          href={`#${j.target}`}
                          onClick={(e) => {
                            e.preventDefault();
                            scrollTo(j.target);
                          }}
                        >
                          <div className={classes.verticalLine} />
                          <div>{j.text}</div>
                          {selected === j.target && (
                            <i className={cx('fa-solid fa-arrow-right', classes.arrowMargin)} />
                          )}
                        </a>
                      ))}
                    </div>
                  ))}
                </div>
              </Col>
              <Col id="help-content" className={classes.contentColumn} md={8}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </Col>
            </>
          )}
        </Row>
      </>
    )
  );
};
export default HelpPage;
HelpPage.displayName = 'HelpPage';
