import { Button } from '@valid-eval/shared-react-components';
import { Map } from 'immutable';
import { connect, ConnectedProps } from 'react-redux';

import GenericModal from 'components/GenericModal';
import { fromNotes } from 'data/features/notes';
import DimensionBoxPlot from 'screens/app/results/components/DimensionBoxPlot';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import AddNote from './AddNote';
import { isTeamLead } from 'utils';

type CompareTeamsAddNoteProps = {
  event?: Record<string, any>;
  team?: Record<string, any>;
  phaseId: string;
  letter: string;
  isZScore: boolean;
};

const mapStateToProps = (state: Map<string, any>, { team }: CompareTeamsAddNoteProps) => ({
  canAddSharedNotes: fromNotes.getCanAddSharedNotes(state?.toJS(), team?.id),
  canAddInternalNotes: fromNotes.getCanAddInternalNotes(state?.toJS(), team?.id),
});

const connector = connect(mapStateToProps);

const CompareTeamsAddNote = ({
  event,
  team,
  letter,
  phaseId,
  canAddSharedNotes,
  canAddInternalNotes,
  isZScore,
}: CompareTeamsAddNoteProps & ConnectedProps<typeof connector>) => {
  const [showModal, openModal, closeModal] = useBooleanFlag(false);

  const isTeamLeadAndQualitativeMode =
    isTeamLead() && event?.judging_ux_mode === 'qualitative_only_mode';

  return (
    <>
      <Button
        className="p-2"
        disabled={!team || !(canAddSharedNotes || canAddInternalNotes)}
        variant="link"
        onClick={openModal}
        data-test-id={`team-${letter}-add-note`}
      >
        <i className={`fa-regular fa-message-plus m-0`} />
      </Button>
      <GenericModal
        show={showModal}
        onCancel={closeModal}
        onConfirm={() => {}}
        name={'add-comment'}
        title={'Add comment'}
        cancelButton={null}
        confirmButton={null}
        size="lg"
        body={
          team && (
            <>
              <div className="d-flex mb-3">
                {/* @ts-ignore */}
                <img src={DimensionBoxPlot[`MarkerImage${letter}`]} alt="Team marker" width={40} />
                <div className="ms-3">
                  <strong>{team.name}</strong>
                  <div>{team.category}</div>
                </div>
              </div>
              {!isTeamLeadAndQualitativeMode && (
                <div className="d-flex mx-3 mb-3">
                  <div className="flex-grow-1">
                    <strong className="me-2 fs-5">
                      {isZScore ? team.sigma?.toFixed?.(2) : team.rank_strength}
                    </strong>
                    <span>{isZScore ? 'Valid Sigmas\u2122' : 'Rank Strength'}</span>
                  </div>
                  <div>
                    <span className="me-2">Valid Rank&trade;:</span>
                    <strong className="fs-5">{team.rank}</strong>
                  </div>
                </div>
              )}
              <AddNote
                teamId={team.id}
                phaseId={phaseId}
                onNoteAdded={closeModal}
                isOrganizerScreen
                withButton={false}
              />
            </>
          )
        }
      />
    </>
  );
};

export default connector(CompareTeamsAddNote);
