import cx from 'classnames';
import range from 'lodash/range';
import debounce from 'lodash/debounce';

import { getBucketLabel } from 'components/helpers';

import Styles from './ScorePicker.module.scss';

const getScores = (grades, selectedGrades) => {
  let scores = grades.reduce((acc, grade) => {
    const min = Number(grade.get('rangemin'));
    const max = Number(grade.get('rangemax'));
    const interval = range(min, max + 1);
    return acc.concat(interval);
  }, []);
  return Array.from(new Set(scores));
};

const ScorePicker = ({
  isDimesionNotesOpen,
  grades,
  validScores,
  readOnly,
  onChange,
  value: selectedScore,
  isQualitativeMode,
  qualitativeMethodology,
}) => {
  const allScores = getScores(grades);
  const canSelect = validScores.size > 0;

  const debouncedOnChange = onChange
    ? debounce(onChange, 300, { leading: false, trailing: true })
    : onChange;

  const isSelected = (score) => {
    return score === selectedScore;
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.scorePicker}>
        {isDimesionNotesOpen && !selectedScore && (
          <div className="text-center text-red01 fw-bold">Score needed</div>
        )}
        {allScores.map((score) => {
          const isAvailable = validScores.has(score);
          const content = isQualitativeMode
            ? getBucketLabel(score, qualitativeMethodology?.buckets)
            : score;
          return (
            <div
              className={cx({
                'd-none': isDimesionNotesOpen && !isSelected(score),
                [Styles.mark]: true,
                [Styles.markLabel]: qualitativeMethodology,
                [Styles.selected]: isSelected(score),
                [Styles.available]: isAvailable && (!readOnly || isSelected(score)),
                [Styles['read-only']]: readOnly,
                [Styles['has-tail']]:
                  !readOnly && isAvailable && validScores.has(score + 1) && !isDimesionNotesOpen,
              })}
              key={`score-${score}`}
              data-test-id={`score-${content}`}
              onClick={() =>
                !isDimesionNotesOpen && isAvailable && debouncedOnChange && debouncedOnChange(score)
              }
            >
              {canSelect && content}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ScorePicker.displayName = 'ScorePicker';
export default ScorePicker;
