import { Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import DimensionNotes from 'components/NotesV2/DimensionNotes';

import { useRef } from 'react';
import Title from '../Title';
import CriterionName from './CriterionName';
import GradeName from './GradeName';
import Styles from './Heat.module.scss';
import ScorePickerFooter from './ScorePickerFooter';
import SubdimensionName from './SubdimensionName';

const RubricDimension = ({
  dimension,
  grades,
  coloredBackground,
  clickedCriteria,
  onChangeCriteria,
  onChangeScore,
  score,
  validScores,
  heatmap,
  showHeatmap,
  selectedByTeam,
  heatCount,
  readOnly,
  isQualitativeMode,
  qualitativeMethodology,
  commentsByDimension,
  phaseId,
  teamId,
  judgeId,
  isDimesionNotesOpen,
  setIsDimensionNotesOpen,
  selfScoring,
}) => {
  const name = dimension.get('name');
  const subdimensions = dimension.get('subdimensions');
  const showFooter = !showHeatmap;
  const ref = useRef(null);

  return (
    <>
      <div
        data-test-id={`dimension-container-${dimension.get('id')}`}
        className={cx({
          [Styles.Dimension]: true,
        })}
      >
        <div className={cx({ 'd-flex': commentsByDimension })}>
          <div
            className={cx('flex-grow-1', {
              'px-4 pt-4 pb-0': showFooter,
              'p-4': !showFooter,
            })}
          >
            <div
              ref={ref}
              className={isDimesionNotesOpen ? 'h-100 d-flex flex-column' : ''}
              style={{ cursor: isDimesionNotesOpen ? 'pointer' : 'default' }}
            >
              <Title component="h2" className="mb-3">
                {name}
              </Title>
              <Row className="m-0 p-0 flex-nowrap align-items-center">
                <SubdimensionName />
                {grades.map((grade) => (
                  <GradeName
                    key={grade.get('id')}
                    grade={grade}
                    isDimesionNotesOpen={isDimesionNotesOpen}
                  />
                ))}
              </Row>
              <div
                className={cx('flex-grow-1 d-flex flex-column', {
                  'justify-content-center': isDimesionNotesOpen,
                })}
              >
                {subdimensions.map((subdimension, index) => (
                  <Row
                    key={subdimension.get('id')}
                    data-test-id={`subdimension-row-${subdimension.get('id')}`}
                    className={`m-0 p-0 flex-nowrap mb-2 subdimension-${index + 1}`}
                  >
                    <SubdimensionName subdimension={subdimension} />
                    {subdimension.get('criteria').map((criterion, index) => (
                      <CriterionName
                        key={criterion.get('id')}
                        criterion={criterion}
                        index={index}
                        heat={heatmap?.(criterion.get('id'))}
                        hits={heatCount?.(criterion.get('id'))}
                        isDimesionNotesOpen={isDimesionNotesOpen}
                        isHeatmap={showHeatmap}
                        isSelected={clickedCriteria?.includes(criterion.get('id'))}
                        isSelectedByTeam={selectedByTeam?.(
                          dimension.get('id'),
                          subdimension.get('id'),
                          criterion.get('id'),
                        )}
                        onChangeCriteria={onChangeCriteria}
                        subdimensionId={subdimension.get('id')}
                      />
                    ))}
                  </Row>
                ))}
              </div>

              {showFooter && (
                <ScorePickerFooter
                  isDimesionNotesOpen={isDimesionNotesOpen}
                  score={score}
                  grades={grades}
                  validScores={validScores}
                  onChange={onChangeScore}
                  labelClassName={Styles.scoreTitle}
                  readOnly={readOnly}
                  isQualitativeMode={isQualitativeMode}
                  qualitativeMethodology={qualitativeMethodology}
                />
              )}
            </div>
          </div>
          {commentsByDimension && (
            <DimensionNotes
              clickedCriteria={clickedCriteria?.toJS()}
              score={score}
              siblingRef={ref}
              onToggle={setIsDimensionNotesOpen}
              dimensionId={dimension.get('id')}
              phaseId={phaseId}
              teamId={teamId}
              judgeId={judgeId}
            />
          )}
        </div>
      </div>
    </>
  );
};

RubricDimension.displayName = 'RubricDimension';
export default RubricDimension;
