import { Button, ButtonGroup } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { connect, ConnectedProps } from 'react-redux';

import { voteNote } from 'data/features/notes';
import { Note as NoteType } from 'data/features/notesTypes';
import { useShowErrorOnRejectedWithValue } from 'utils/hooks/useShowErrorOnRejectedWithValue';
import { success as showSuccess } from 'data/actions/notifications';

type NoteVotesProps = {
  note: NoteType;
};

const mapDispatchToProps = { voteNote, showSuccess };

const connector = connect(null, mapDispatchToProps);

const NoteVotes = ({
  note,
  voteNote,
  showSuccess,
}: NoteVotesProps & ConnectedProps<typeof connector>) => {
  const showErrorOnRejectedWithValue = useShowErrorOnRejectedWithValue();

  const handleVote = (voteValue: number) => async () => {
    const vote = note.vote === voteValue ? 0 : voteValue;
    const result = showErrorOnRejectedWithValue(
      await voteNote({
        voteValue: vote,
        noteId: note.id,
        phaseId: note.phaseId,
        teamId: note.teamId,
      }),
    );
    if (result) showSuccess('The vote has been saved');
    return result;
  };

  return (
    <>
      <ButtonGroup>
        {(note.canVote || !!note.upVotes) && (
          <Button
            name="up-vote"
            variant="vote"
            className={cx('first py-1 px-2', { voted: note.vote === 1 })}
            onClick={handleVote(1)}
            disabled={!note.canVote}
          >
            <i
              className={cx('m-0 fa-thumbs-up me-2', {
                'fa-regular': note.vote !== 1,
                'fa-solid': note.vote === 1,
              })}
            />
            {!!note.upVotes && note.upVotes}
          </Button>
        )}
        {(note.canVote || !!note.downVotes) && (
          <Button
            name="down-vote"
            variant="vote"
            className={cx('py-1 px-2', { voted: note.vote === -1 })}
            onClick={handleVote(-1)}
            disabled={!note.canVote}
          >
            <i
              className={cx('m-0 fa-thumbs-down me-2', {
                'fa-regular': note.vote !== -1,
                'fa-solid': note.vote === -1,
              })}
            />
            {!!note.downVotes && note.downVotes}
          </Button>
        )}
      </ButtonGroup>
    </>
  );
};

export default connector(NoteVotes);
