import cx from 'classnames';

import HeatmapPills from 'components/RubricDimension/HeatPills';
import { spinnerWhileLoading } from '../Loading';
import RubricDimension from '../RubricDimension';
import { heatRange } from '../RubricDimension/CriterionName';
import { formatJudges } from '../Scores/EvaluationQuartile';
import Styles from './Heatmap.module.scss';
import { useMemo } from 'react';

const getAllCriterionIds = (dimensions) => {
  return dimensions
    ?.toJS?.()
    .map((dimension) =>
      dimension.subdimensions.map((subdimension) =>
        subdimension.criteria.map((criterion) => criterion.id),
      ),
    )
    .flat()
    .flat();
};

const Heatmap = ({ rubric, evaluation, scores, selfScore }) => {
  const heatCount = (criteriaId) => {
    const scoreMap = (score) =>
      score.get('clicked_criteria').count((criterion) => criteriaId === criterion);
    return scores.map(scoreMap).reduce((a, b) => a + b, 0);
  };

  const heatmap = (criteriaId) => {
    const count = heatCount(criteriaId);
    return count / evaluation.get('judges_count');
  };

  const selectedByTeam = (dimensionId, subdimensionId, criteriaId) => {
    return (
      selfScore &&
      selfScore.get('complete') &&
      selfScore.get('clicked_criteria').includes(criteriaId)
    );
  };

  const grades = rubric.get('grades');
  const dimensions = rubric.get('dimensions');

  const heatRanges = useMemo(() => {
    const criterionIds = getAllCriterionIds(dimensions);
    return criterionIds.reduce((acc, criterionId) => {
      const heat = heatmap(criterionId);
      const clicks = heatCount(criterionId);
      const range = heatRange(heat);
      acc[range] = acc[range] ? [...acc[range], clicks] : [clicks];
      return acc;
    }, {});
  }, [dimensions, scores]);

  return (
    <div>
      <header className={cx(Styles.Stat, 'mt-2 me-4 d-flex align-items-center')}>
        <HeatmapPills heatRanges={heatRanges} />
        {formatJudges(evaluation.get('judges_count'))}
      </header>
      <div className={Styles.container}>
        <div className={cx(Styles.dimensionsContainer, Styles.fullWidth)}>
          {dimensions.map((dimension, index) => {
            const dimId = String(dimension.get('id'));
            return (
              <RubricDimension
                dimension={dimension}
                grades={grades}
                key={`dim-${dimId}`}
                coloredBackground={index % 2 === 0}
                heatmap={heatmap}
                heatCount={heatCount}
                selectedByTeam={selectedByTeam}
                showHeatmap
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

Heatmap.displayName = 'Heatmap';

export const HeatmapWithLoading = spinnerWhileLoading((props) => props.rubric && props.evaluation)(
  Heatmap,
);

export default Heatmap;
