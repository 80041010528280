import { Button } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import { Intro as IntroType } from 'data/features/introsTypes';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import IntroFormModal from './IntroFormModal';

type IntroEditProps = {
  intro: IntroType;
};

const IntroEdit = ({ intro }: IntroEditProps) => {
  const [showModal, open, close] = useBooleanFlag();

  return (
    intro.canEdit && (
      <>
        <IntroFormModal
          intro={intro}
          teamId={intro.teamId}
          phaseId={intro.phaseId}
          judgeId={intro.writerId}
          onClose={close}
          show={showModal}
        />
        <Button
          name="edit"
          variant="link"
          className={cx('p-0', { 'me-3': intro.canDelete })}
          onClick={open}
        >
          <i className="m-0 fa-regular fa-pen-to-square" />
        </Button>
      </>
    )
  );
};

export default IntroEdit;
