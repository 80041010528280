import React, { useEffect, useState } from 'react';
import {
  Container,
  Form,
  OptionValue,
  ReactSelect,
  SelectMultiple,
} from '@valid-eval/shared-react-components';
import GenericModal from 'components/GenericModal';
import StatusAndRangeSelector from 'screens/app/results/StatusAndRangeSelector';
import { filterData } from './TeamSelector';
import { load } from 'data/actions/roleSummaries';
import {
  getSelectedPhaseIdForCurrentEvent,
  getTeamsAsOptions,
  getResultsForSelectedPhase,
} from 'data/reducers';
import { connect, ConnectedProps } from 'react-redux';
import withRouter from 'routes/withRouter';
import { isTeamLead } from 'utils';

type OwnProps = {
  eventId: string;
  show: boolean;
  customModalTitle?: string;
  onCancel(): void;
  onConfirm({ teams }: { teams: OptionValue[] }): void;
  phaseFinalRank: boolean;
  pdfTeamRange: { first: number | string; last: number | string };
  totalEvaluations: number;
  pdfTeamStatusCheckboxes: { in: boolean; out: boolean; bubble: boolean; empty: boolean };
  handleOnChangePDFTeamStatusCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setPdfTeamRange: (range: { first: number | string; last: number | string }) => void;
  isSinglePDF: boolean;
  setIsSinglePDF: (value: boolean) => void;
  showBoxPlot: boolean;
  setShowBoxPlot: (value: boolean) => void;
  isQualitativeMode: boolean;
};

const connector = connect(
  (state, ownProps: OwnProps) => ({
    teamOptions: getTeamsAsOptions(state, ownProps),
    // @ts-ignore
    selectedPhaseId: getSelectedPhaseIdForCurrentEvent(state, ownProps),
    // @ts-ignore
    evaluations: getResultsForSelectedPhase(state, ownProps),
  }),
  {
    load,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type TeamSelectorModalProps = PropsFromRedux & OwnProps;

const TeamSelectorModal = ({
  load,
  show,
  onCancel,
  onConfirm,
  selectedPhaseId,
  customModalTitle,
  phaseFinalRank,
  pdfTeamRange,
  totalEvaluations,
  pdfTeamStatusCheckboxes,
  evaluations,
  handleOnChangePDFTeamStatusCheckbox,
  setPdfTeamRange,
  isSinglePDF,
  setIsSinglePDF,
  showBoxPlot,
  setShowBoxPlot,
  isQualitativeMode,
}: TeamSelectorModalProps) => {
  const filteredTeams = filterData(evaluations, pdfTeamStatusCheckboxes);
  const [selectedTeams, setSelectedTeams] = useState<OptionValue[]>(filteredTeams);

  useEffect(() => {
    load('teams', selectedPhaseId);
  }, [selectedPhaseId, load]);

  useEffect(() => {
    setSelectedTeams(filteredTeams);
  }, [pdfTeamStatusCheckboxes, evaluations]);

  const handleTeamsChange = (
    value: ReactSelect.MultiValue<OptionValue> | ReactSelect.SingleValue<OptionValue>,
  ) => {
    const newSelectedTeams = value as OptionValue[];
    setSelectedTeams(newSelectedTeams);
  };

  return (
    <GenericModal
      isButtonDisabled={selectedTeams.length === 0}
      cancelButton="Cancel"
      confirmButton="Export PDF"
      className={undefined}
      body={
        <Container>
          <p>Choose the status of the teams you would like to include in the PDF.</p>
          <StatusAndRangeSelector
            phaseFinalRank={phaseFinalRank}
            pdfTeamRange={pdfTeamRange}
            totalEvaluations={totalEvaluations}
            pdfTeamStatusCheckboxes={pdfTeamStatusCheckboxes}
            handleOnChangePDFTeamStatusCheckbox={handleOnChangePDFTeamStatusCheckbox}
            setPdfTeamRange={setPdfTeamRange}
          />
          {!phaseFinalRank && (
            <>
              <p>Choose the teams you would like to include in the PDF.</p>
              <SelectMultiple
                placeholder="Filter by name, prefix or panel"
                options={filteredTeams}
                value={selectedTeams}
                onChange={handleTeamsChange}
                backspaceRemovesValue={false}
              />
            </>
          )}
          <div className="d-flex align-items-center">
            <span className="form-check me-4 mt-4">
              <Form.Check
                id={'single-PDF-checkbox-1'}
                type={'checkbox'}
                onChange={(e) => setIsSinglePDF(e.target.checked)}
                checked={isSinglePDF}
                label={'Download all reports in a single PDF'}
              />
            </span>
            {!(isQualitativeMode && isTeamLead()) && (
              <span className="form-check mt-4">
                <Form.Check
                  id={'set-show-plotbox-checkbox'}
                  type={'checkbox'}
                  onChange={(e) => setShowBoxPlot(e.target.checked)}
                  checked={showBoxPlot}
                  label={'Display Box Plots'}
                />
              </span>
            )}
          </div>
        </Container>
      }
      name={'team-files-report-confirm'}
      title={customModalTitle || 'Team Files Report'}
      onCancel={onCancel}
      onConfirm={() => onConfirm({ teams: selectedTeams })}
      show={show}
    />
  );
};

export default withRouter(connector(TeamSelectorModal));
