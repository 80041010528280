import { Button } from '@valid-eval/shared-react-components';
import { Map } from 'immutable';

import { fromIntros } from 'data/features/intros';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import IntroFormModal from './components/IntroFormModal';
import { connect, ConnectedProps } from 'react-redux';

type AddIntroProps = {
  teamId: string;
  phaseId: string;
  judgeId: string;
};

const mapStateToProps = (state: Map<string, any>) => ({
  introOffered: fromIntros.getIntroOffered(state?.toJS()),
  viewed: fromIntros.getViewed(state?.toJS()),
});

const connector = connect(mapStateToProps);

const AddIntro = ({
  teamId,
  phaseId,
  judgeId,
  introOffered,
  viewed,
}: AddIntroProps & ConnectedProps<typeof connector>) => {
  const [showModal, open, close] = useBooleanFlag(false);

  return (
    <>
      <IntroFormModal
        show={showModal}
        onClose={close}
        teamId={teamId}
        phaseId={phaseId}
        judgeId={judgeId}
      />
      <div className="d-flex align-items-center justify-content-center my-3">
        <div>
          <span className="fa-stack fa-lg">
            <i className="fa-solid fa-circle fa-stack-2x text-blue04"></i>
            <i className="fa-solid fa-handshake fa-stack-1x fa-inverse"></i>
          </span>
        </div>

        <p className="text-blue04 m-0">
          <small>
            <strong>
              Please share my judge profile information with this <br />
              company. I am interested in connecting with them.
            </strong>
          </small>
        </p>
        <div className="ms-3">
          <Button
            id="add-intro-button"
            variant="success"
            onClick={open}
            disabled={introOffered || viewed}
          >
            {introOffered ? 'Intro made!' : 'Introduce Me'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default connector(AddIntro);
