import { Col, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import GenericModal from 'components/GenericModal';
import { createMultiple } from 'data/actions/assignments';
import { error as showError, success as showSuccess } from 'data/actions/notifications';
import { load } from 'data/actions/roleSummaries';
import { getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import { Judge, Team } from '../../shared/types';
import TriageModalTable from './TriageModalTable';

type OwnProps = {
  entity: 'teams' | 'judges';
  show: boolean;
  close(): void;
  selected: Judge[] | Team[];
  reload(): void;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  phase: getSelectedPhase(state, ownProps)?.toJS?.(),
});

const mapDispatchToProps = { load, createMultiple, showError, showSuccess };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type TriageModalProps = OwnProps & PropsFromRedux;

const TriageModal = ({
  close,
  createMultiple,
  entity,
  load,
  phase,
  selected,
  show,
  showError,
  showSuccess,
  reload,
}: TriageModalProps) => {
  const [isLoading, startLoading, finishLoading] = useBooleanFlag();

  const [selectedJudges, setSelectedJudges] = useState<Judge[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);

  const loadRoleSummaries = async () => {
    await load(entity === 'judges' ? 'teams' : 'judges', phase.id);
  };

  useEffect(() => {
    if (phase?.id && show) loadRoleSummaries();
  }, [phase?.id, show]);

  const handleAddMatches = async () => {
    startLoading();
    try {
      const res = await createMultiple(
        phase.id,
        selectedJudges.map((j) => j.id),
        selectedTeams.map((t) => t.id),
      );
      // @ts-ignore
      if (res.error) throw new Error(res.error);
      showSuccess('Matches added successfully!', 'Success');
      reload();
      close();
    } catch {
      showError('Failed to add matches, please try again.', 'Error');
    } finally {
      finishLoading();
    }
  };

  return (
    <GenericModal
      name="triage"
      cancelButton="Cancel"
      confirmButton="Add Matches"
      isButtonDisabled={isLoading || !(selectedJudges.length && selectedTeams.length)}
      isCancelButtonDisabled={isLoading}
      onCancel={close}
      onConfirm={handleAddMatches}
      show={show}
      title={'Triage'}
      size={'xl'}
      fullscreen={'xl-down'}
      body={
        <>
          <Row className={cx({ 'flex-row-reverse': entity === 'teams' })}>
            <Col md={6} className="text-center p-1">
              <div className="border px-1 pt-2 rounded">
                <h2>Judges</h2>
                <TriageModalTable
                  entity={'judges'}
                  preselected={entity === 'judges' ? selected : []}
                  setSelectedEntities={setSelectedJudges}
                  phase={phase}
                />
              </div>
            </Col>

            <Col md={6} className="text-center p-1">
              <div className="border px-1 pt-2 rounded">
                <h2>Teams</h2>
                <TriageModalTable
                  entity={'teams'}
                  preselected={entity === 'teams' ? selected : []}
                  setSelectedEntities={setSelectedTeams}
                  phase={phase}
                />
              </div>
            </Col>
          </Row>
        </>
      }
      footerExtraContent={
        <div className="d-flex flex-grow-1 align-items-end px-3">
          <div className="text-center">
            <p className="fw-bold lh-1 mb-1 text-blue03">
              <small>
                Teams
                <br />
                Selected
              </small>
            </p>
            <span className="fw-bolder fs-5 text-blue03">{selectedTeams.length}</span>
          </div>
          <div className="text-center mx-2 pb-1 text-muted">
            <i className="fa-solid fa-times" />
          </div>
          <div className="text-center">
            <p className="fw-bold lh-1 mb-1 text-blue03">
              <small>
                Judges
                <br />
                Selected
              </small>
            </p>
            <span className="fw-bolder fs-5 text-blue03">{selectedJudges.length}</span>
          </div>
          <div className="text-center mx-2 pb-1 text-muted">
            <i className="fa-solid fa-equals" />
          </div>
          <div className="text-center">
            <p className="fw-bold lh-1 mb-1 text-black01">
              <small>
                New
                <br />
                Matches
              </small>
            </p>
            <span className="fw-bolder fs-5 text-black01">
              {selectedJudges.length * selectedTeams.length}
            </span>
          </div>
        </div>
      }
    />
  );
};

export default withRouter(connector(TriageModal));
